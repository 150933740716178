import React, { useState, useEffect } from 'react';
import Modal from "react-bootstrap/Modal";
import Bigcontact from "../Components/Bigcontact";
import Header from "../Components/Header";
import Footer from "../Components/footer";
import Minicontact from "../Components/Minicontact";
import Faq from "../Components/Faq";
import SocialmediaCard from "../Components/SocialmediaCard";
import Testimonial1 from "../Components/Testimonial1";
import Chatbox from "../Components/Chatbox";
import SEOPage from "../Components/SEOPage";
import Blog1 from "../Components/Blog1";
import { Helmet } from "react-helmet";


function Webdev() {


  const [categories, setCategories] = useState([]);

  useEffect(() => {
    fetch('https://blog.datametricks.com/wp-json/wp/v2/categories')
      .then((response) => response.json())
      .then((data) => setCategories(data.slice(0,3)))
      .catch((error) => console.error('Error fetching categories:', error));
  }, []);

 
  const categoryId=2;

  const serviceSchemaData3 =
  [
  {
    "@context": "https://schema.org",
    "@type": "Service",
    "serviceType": "Social Media Marketing",
    "provider": {
      "@type": "Organization",
      "name": "Datametricks Consulting Pvt Ltd",
      "url": "https://www.datametricks.com/",
      "logo": "https://d3vqz9qyn2r1xe.cloudfront.net/imges/comlogo.svg",
      "contactPoint": {
        "@type": "ContactPoint",
        "telephone": "+91 7303946657",
        "contactType": "Customer Service",
        "email": "contact@datametricks.com"
      }
    },
    "description": "Datametricks provides social media marketing services, offering strategies to increase brand engagement, followers, and website traffic through targeted social campaigns.",
    "areaServed": {
      "@type": "Place",
      "name": "India"
    }
  }
];

const smmbg = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/smm.png";
const sec5dot1 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/sec5dot1.png";
const sec5dot2 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/sec5dot2.png";
const sec5dot3 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/sec5dot3.png";
const sec5dot4 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/sec5dot4.png";
const stagesbg = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/stagesbg.png";
const img1sec1 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/smmimg1.png";
// const expertisesmm = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/expertisesmm.png";
const seonewimg1 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/seoinewimg1.png";
const seonewimg2 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/seonewimg2.png";
const seonewimg3 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/seonewimg3.png";
const seonewimg4 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/seonewimg4.png";
const seonewimg5 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/seonewimg5.png";
const seonewimg6 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/seonewimg6.png";
const seonewimg7 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/seonewimg7.png";
const seonewimg8 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/seonewimg8.png";
// const testimonialimage = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/testimonial-img.png"


  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  var faqData=[
    {que:"1. Which social media platforms will you focus on for my business?",ans:"We tailor our approach to your specific business needs. We'll analyze your target audience and industry to determine the most effective platforms to reach your goals. This could include Facebook, Instagram, LinkedIn, Twitter, TikTok, or other relevant platforms." },
    {que:"2. How do you measure the success of your social media marketing campaigns? ", ans:"We track a variety of metrics to measure success, including:- (i) Engagement: Likes, comments, shares, clicks, etc. (ii) Reach & Impressions: How many people are seeing your content? (iii) Follower Growth: Are you attracting new followers? (iv)Website Traffic: Is social media driving traffic to your site? (v)Conversions: Are social media efforts leading to sales or leads? (vi) Brand Awareness: Is your brand becoming more recognized?  We provide regular reports with insights and analysis to demonstrate the impact of our efforts."},
    {que:"3. What kind of content can I expect to see on my social media channels?",ans:"Our content is always tailored to your brand and audience. We create a mix of:-  (i) Engaging visuals: High-quality photos, videos, and graphics. (ii) Informative posts: Industry news, tips, and educational content. (iii) Promotional content: Highlighting your products or services. (iv) Interactive content: Polls, quizzes, contests, etc. "},
   
];

  return (
    <div >
       <SEOPage 
                title="Social Media Marketing Services | Boost Your Brand with Datametricks"
                description="At Datametricks, we provide effective social media marketing strategies to grow your
brand, increase engagement, and drive traffic across platforms like Facebook, Instagram,
and LinkedIn."
                canonicalUrl="https://www.datametricks.com/service/social-media-marketing"/>

      <Helmet>
             <script type="application/ld+json">
                  {JSON.stringify(serviceSchemaData3)}
             </script>
      </Helmet>
       
      <Header />

      {/* section 1 */}
      <section>
        <div
          className="pb-5"
          style={{
            backgroundImage: `url(${smmbg})`,
            backgroundRepeat: "no-repeat",
            height: "fit-content",
            backgroundSize: "cover",
            backgroundPosition: "100% 100%",
          }}
        >
          <div style={{ paddingTop: "120px" }} className="container padtop">
            <div className="row align-items-center justify-content-center">
              <div className="col-lg-6 justify-content-center">
                <div style={{ lineHeight: "1.3" }}>
                  <p className="Seopara1 font-family fw-800 fc-black fs-40 mb-0 font-23">
                    Boost Your Brand's Presence{" "}
                  </p>
                  <p className=" Seopara1 font-family fw-800 fc-black fs-40 mb-0 font-23">
                    {" "}
                    With Expert{" "}
                    <span className="Seopara1 font-family fw-800 pm-color fs-40 font-23">
                      Social Media{" "}
                    </span>
                  </p>
                  <p className=" Seopara1 font-family fw-800 pm-color fs-40 font-23">
                    {" "}
                    Services 
                  </p>
                </div>

                <p className="para1 Seopara3 font-18">
                  Engage, Grow, and Connect with Your Audience on All Major
                  Platforms
                </p>
                {/* =========================Button Modal============= */}
                <div>
                  <button
                    className=" Seopara3 git-button font-family fw-500 fs-18 "
                    onClick={handleShow}
                  >
                    Get in Touch
                  </button>

                  <Modal show={show} onHide={handleClose} className="modal1">
                    <Modal.Header
                      closeButton
                      className="modelheader1"
                    ></Modal.Header>
                    <Modal.Body className="modelbody1">
                      <Bigcontact />
                    </Modal.Body>
                  </Modal>
                </div>
                {/* ===========Button Model End===================== */}
                <div className="pt-4 d-flex gap-5">
                  <div>
                    <p className="fs-24 font-family fc-black fw-600 mb-0">
                      1000+
                    </p>
                    <p className="para1">Successful Campaigns</p>
                  </div>
                  <div>
                    <p className="fs-24 font-family fc-black fw-600 mb-0">
                      534+
                    </p>
                    <p className="para1">Brands Joined</p>
                  </div>
                  <div>
                    <p className="fs-24 font-family fc-black fw-600 mb-0">
                      30+
                    </p>
                    <p className="para1">Marketing Experts</p>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-1"></div> */}
              <div className="col-lg-5 text-end">
                <img className="img-fluid" src={img1sec1} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* section 1 end */}

      {/* section 2 */}
      <section>
        <div className="sec2 pt-lg-5 pb-lg-4 mt-10">
          <p className="sec2heading Seopara1 text-center mb-0 font-23">
            {" "}
            Connect, Engage, Grow: Your Social{" "}
          </p>
          <p className="sec2heading text-center Seopara1 font-23">
            {" "}
            Media Success Starts Here {" "}
          </p>

          <div>
            {/* <Card heading="Social Media Strategy Development" text="Lorem ipsum dolor sit amet consectetur. Lacus at tellus pulvinar a ut risus. Sollicitudin venenatis sit vehicula velit. Convallis ultrices fermentum facilisis pellentesque pharetra egestas pulvinar. Mauris duis mauris vitae adipiscing. Arcu nunc sed fusce dictum pretium. Id at adipiscing massa a malesuada pretium nulla non. Ut neque hendrerit egestas ut tellus vulputate." image={img7}/>
                    <Card heading="Social Media Strategy Development" text="Lorem ipsum dolor sit amet consectetur. Lacus at tellus pulvinar a ut risus. Sollicitudin venenatis sit vehicula velit. Convallis ultrices fermentum facilisis pellentesque pharetra egestas pulvinar. Mauris duis mauris vitae adipiscing. Arcu nunc sed fusce dictum pretium. Id at adipiscing massa a malesuada pretium nulla non. Ut neque hendrerit egestas ut tellus vulputate." image={img7}/>
                    <Card heading="Social Media Strategy Development" text="Lorem ipsum dolor sit amet consectetur. Lacus at tellus pulvinar a ut risus. Sollicitudin venenatis sit vehicula velit. Convallis ultrices fermentum facilisis pellentesque pharetra egestas pulvinar. Mauris duis mauris vitae adipiscing. Arcu nunc sed fusce dictum pretium. Id at adipiscing massa a malesuada pretium nulla non. Ut neque hendrerit egestas ut tellus vulputate." image={img7}/>
                    <Card heading="Social Media Strategy Development" text="Lorem ipsum dolor sit amet consectetur. Lacus at tellus pulvinar a ut risus. Sollicitudin venenatis sit vehicula velit. Convallis ultrices fermentum facilisis pellentesque pharetra egestas pulvinar. Mauris duis mauris vitae adipiscing. Arcu nunc sed fusce dictum pretium. Id at adipiscing massa a malesuada pretium nulla non. Ut neque hendrerit egestas ut tellus vulputate." image={img7}/>
                    <Card heading="Social Media Strategy Development" text="Lorem ipsum dolor sit amet consectetur. Lacus at tellus pulvinar a ut risus. Sollicitudin venenatis sit vehicula velit. Convallis ultrices fermentum facilisis pellentesque pharetra egestas pulvinar. Mauris duis mauris vitae adipiscing. Arcu nunc sed fusce dictum pretium. Id at adipiscing massa a malesuada pretium nulla non. Ut neque hendrerit egestas ut tellus vulputate." image={img7}/> */}

            <SocialmediaCard />
          </div>

          {/* <div className='smmimg2 mt-5'>
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-lg-6 ps-5 pt-4">
                                        <p className='cutting-title'>Strategy & Consulting</p>
                                        <p className='cutting-subtitle'>Lorem ipsum dolor sit amet consectetur. Lacus at tellus pulvinar a ut risus. Sollicitudin venenatis sit vehicula velit. Convallis ultrices fermentum facilisis pellentesque pharetra egestas pulvinar. Mauris duis mauris vitae adipiscing. Arcu nunc sed fusce dictum pretium. Id at adipiscing massa a malesuada pretium nulla non. Ut neque hendrerit egestas ut tellus vulputate.</p>
                                    </div>
                                    <div className="col-lg-4">

                                    </div>
                                </div>

                            </div>
                        </div> */}
          {/* 2nd */}

          {/* <div className='smmimg3 mt-5'>
                            <div className="container">
                                <div className="row align-items-center">

                                    <div className="col-lg-6">

                                    </div>
                                    <div className="col-lg-6  mt-5">
                                        <p className='cutting-title'>UI/UX Design</p>
                                        <p className='cutting-subtitle'>Lorem ipsum dolor sit amet consectetur. Lacus at tellus pulvinar a ut risus. Sollicitudin venenatis sit vehicula velit. Convallis ultrices fermentum facilisis pellentesque pharetra egestas pulvinar. Mauris duis mauris vitae adipiscing. Arcu nunc sed fusce dictum pretium. Id at adipiscing massa a malesuada pretium nulla non. Ut neque hendrerit egestas ut tellus vulputate.</p>
                                    </div>
                                </div>

                            </div>
                        </div> */}

          {/* <div className='smmimg4 mt-5'>
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-lg-6 ps-5 pt-4">
                                        <p className='cutting-title'>Native App Development (Android/iOS)</p>
                                        <p className='cutting-subtitle'>Lorem ipsum dolor sit amet consectetur. Lacus at tellus pulvinar a ut risus. Sollicitudin venenatis sit vehicula velit. Convallis ultrices fermentum facilisis pellentesque pharetra egestas pulvinar. Mauris duis mauris vitae adipiscing. Arcu nunc sed fusce dictum pretium. Id at adipiscing massa a malesuada pretium nulla non. Ut neque hendrerit egestas ut tellus vulputate.</p>
                                    </div>
                                    <div className="col-lg-4">

                                    </div>
                                </div>

                            </div>
                        </div> */}
          {/* 4th */}
          {/* <div className='smmimg5 mt-5'>
                            <div className="container">
                                <div className="row align-items-center">

                                    <div className="col-lg-6">

                                    </div>
                                    <div className="col-lg-6  mt-5">
                                        <p className='cutting-title'>Cross-Platform App Development</p>
                                        <p className='cutting-subtitle'>Lorem ipsum dolor sit amet consectetur. Lacus at tellus pulvinar a ut risus. Sollicitudin venenatis sit vehicula velit. Convallis ultrices fermentum facilisis pellentesque pharetra egestas pulvinar. Mauris duis mauris vitae adipiscing. Arcu nunc sed fusce dictum pretium. Id at adipiscing massa a malesuada pretium nulla non. Ut neque hendrerit egestas ut tellus vulputate.</p>
                                    </div>
                                </div>

                            </div>
                        </div> */}
          {/* <div className='smmimg6 mt-5'>
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-lg-6 ps-5 pt-4">
                                        <p className='cutting-title'>App Development for Wearables</p>
                                        <p className='cutting-subtitle'>Lorem ipsum dolor sit amet consectetur. Lacus at tellus pulvinar a ut risus. Sollicitudin venenatis sit vehicula velit. Convallis ultrices fermentum facilisis pellentesque pharetra egestas pulvinar. Mauris duis mauris vitae adipiscing. Arcu nunc sed fusce dictum pretium. Id at adipiscing massa a malesuada pretium nulla non. Ut neque hendrerit egestas ut tellus vulputate.</p>
                                    </div>
                                    <div className="col-lg-4">

                                    </div>
                                </div>

                            </div>
                        </div> */}
        </div>
      </section>

      {/* <section className="socialcon1">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div
                style={{
                  border: "1.5px solid #00539A",
                  borderRadius: "61%",
                  width: "fit-content",
                  height: "fit-content",
                  padding: "25px",
                }}
              >
               
                <h3 style={{ color: "#00539A" }}>Why Choose</h3>
              </div>
              <div style={{ lineHeight: "1.3" }} className="">
                <p className="font-family fw-800 fc-black fs-40 mb-0 Seopara1">
                  Why Datametricks is Your
                </p>
                <p className="font-family fw-800 pm-color fs-40 mb-0 Seopara1">
                  Social Media Marketing
                </p>
                <p className="font-family fw-800 fc-black fs-40 Seopara1">
                  Partner
                </p>

           
                <div>
                  <button
                    className=" Seopara3 git-button font-family fw-500 fs-18"
                    onClick={handleShow}
                  >
                    Get in Touch
                  </button>

                  <Modal show={show} onHide={handleClose} className="modal1">
                    <Modal.Header
                      closeButton
                      className="modelheader1"
                    ></Modal.Header>
                    <Modal.Body className="modelbody1">
                      <Bigcontact />
                    </Modal.Body>
                  </Modal>
                </div>
               
              </div>
            </div>
            <div className="col-lg-6 socialcon2 ">
              <div>
                <img src={daimond} alt="" />
              </div>
              <p className="font-family fw-600 fs-22 pt-2 Seopara2">
                Understanding social media landscape and target audiences
              </p>
              <div className="pt-3">
                <img src={daimond} alt="" />
              </div>
              <p className="font-family fw-600 fs-22 pt-2 Seopara2">
                Understanding social media landscape and target audiences
              </p>
              <div className="pt-3">
                <img src={daimond} alt="" />
              </div>
              <p className="font-family fw-600 fs-22 pt-2 Seopara2">
                Understanding social media landscape and target audiences
              </p>
            </div>
          </div>
        </div>
      </section> */}

      <section>
              <div className="">
                  <p className="sec2heading text-center mb-0 Seopara1 font-23"> Why Datametricks is Your Social </p>
                  <p className="sec2heading text-center Seopara1 font-23"> Media Marketing Partner  </p>
              </div>

              <div className="container mt-lg-5">
                <div className="row">
                    <div className="col-lg-4">
                            <img src={seonewimg1} alt="" className="img-fluid "/>
                    </div>
                    <div className="col-lg-3 d-none d-sm-none d-md-block d-lg-block">
                          <img src={seonewimg2} alt="" className="img-fluid  "/>
                          <img src={seonewimg3} alt="" className="img-fluid pt-4  pb-lg-4"/>
                    </div>
                    <div className="col-lg-5 textcenter">
                        <p className=" pe-lg-5 font-18 mt-10" style={{fontSize:'18px', fontWeight:'500', fontFamily:"Plus Jakarta Sans, sans-serif",marginTop:'13%' }}>In today's digital age, social media is a powerful tool for businesses to connect with their audience, build brand awareness, and drive growth. But navigating the ever-changing social media landscape can be overwhelming. That's where Datametricks comes in. </p>
                    </div>
                </div>

              </div>

              <div>
                <p  style={{fontSize:'25px', fontWeight:'400', fontFamily:"Plus Jakarta Sans, sans-serif", }} className="text-center mt-lg-5 font-23">As your trusted social media marketing partner, we offer</p>
              </div>

              <div className="container mt-lg-5 mt-10">
                <div className="row">
                    <div className="col-lg-4">
                            <img src={seonewimg4} alt="" className="img-fluid "/>
                    </div>
                    <div className="col-lg-4 textcenter">
                            <p className="mt-lg-5 mt-10 mb-2 font-23" style={{fontSize:'23px', fontWeight:'500', fontFamily:"Plus Jakarta Sans, sans-serif", }}>Deep Understanding of the Social Media Landscape</p>
                            <p className="font-18" style={{fontSize:'16px', fontWeight:'400', fontFamily:"Plus Jakarta Sans, sans-serif", }}>We stay ahead of the curve on the latest trends and algorithms, ensuring your brand is always visible and relevant.</p>
                    </div>
                    <div className="col-lg-4">
                    </div>
                </div>

              </div>

              <div className="container mt-lg-4">
                <div className="row">
                     <div className="col-lg-4">
                     </div>
                     <div className="col-lg-4 textcenter">
                            <p className="mt-lg-5 px-lg-2 mb-2 font-23" style={{fontSize:'23px', fontWeight:'500', fontFamily:"Plus Jakarta Sans, sans-serif", }}>Targeted Audience Analysis</p>
                            <p className="px-lg-2 font-18" style={{fontSize:'16px', fontWeight:'400', fontFamily:"Plus Jakarta Sans, sans-serif", }}>We identify your ideal customers and craft tailored strategies to reach them effectively on the right platforms.</p>
                    </div>
                    <div className="col-lg-4">
                            <img src={seonewimg5} alt="" className="img-fluid "/>
                    </div>
                    
                   
                </div>

              </div>

              <div className="container mt-4 ">
                <div className="row">
                    <div className="col-lg-4">
                            <img src={seonewimg6} alt="" className="img-fluid "/>
                    </div>
                    <div className="col-lg-4 textcenter">
                            <p className="mt-lg-5 mt-10 mb-2 font-23" style={{fontSize:'23px', fontWeight:'500', fontFamily:"Plus Jakarta Sans, sans-serif", }}>Data-Driven Campaigns</p>
                            <p className="font-18" style={{fontSize:'16px', fontWeight:'400', fontFamily:"Plus Jakarta Sans, sans-serif", }}>We develop and execute campaigns that are not only creative but also measurable, allowing us to optimize for maximum impact. </p>
                    </div>
                    <div className="col-lg-4">
                    </div>
                </div>

              </div>

              <div className="container mt-lg-4  ">
                <div className="row">
                     <div className="col-lg-4">
                     </div>
                     <div className="col-lg-4 textcenter">
                            <p className="mt-lg-5 px-lg-2 mb-2 font-23" style={{fontSize:'23px', fontWeight:'500', fontFamily:"Plus Jakarta Sans, sans-serif", }}>Eye-Catching Visuals & Engaging Content</p>
                            <p className="px-lg-2 font-18" style={{fontSize:'16px', fontWeight:'400', fontFamily:"Plus Jakarta Sans, sans-serif", }}>Our team of skilled designers and copywriters create content that captures attention and inspires action. </p>
                    </div>
                    <div className="col-lg-4">
                            <img src={seonewimg7} alt="" className="img-fluid "/>
                    </div>
                    
                   
                </div>

              </div>

              <div className="container mt-4">
                <div className="row">
                    <div className="col-lg-4">
                            <img src={seonewimg8} alt="" className="img-fluid "/>
                    </div>
                    <div className="col-lg-4 textcenter">
                            <p className="mt-lg-5 mb-lg-2 font-23 mt-10" style={{fontSize:'23px', fontWeight:'500', fontFamily:"Plus Jakarta Sans, sans-serif", }}>Comprehensive Results Tracking</p>
                            <p className="font-18" style={{fontSize:'16px', fontWeight:'400', fontFamily:"Plus Jakarta Sans, sans-serif", }}>Partner with Datametricks to unlock the full potential of social media for your business. We'll help you build a strong online presence, connect with your target audience, and achieve your marketing goals. </p>
                    </div>
                    <div className="col-lg-4">
                    </div>
                </div>

              </div>
      </section>

      <div className="pt-lg-5">
        <p className="sec2heading text-center mb-0 Seopara1 font-23">
          {" "}
          Our Proven Process for Social{" "}
        </p>
        <p className="sec2heading text-center Seopara1 font-23"> Media Success </p>
      </div>
      <div className="container pt-lg-4">
        <div className="row">
          <div className="col-lg-4">
            <div className="d-flex  gap-2">
              <div>
                <img src={sec5dot1} alt="" />
              </div>
              <div>
                <p className="sec5title mb-0 mt-1">1.Initial Consultation</p>
                <p style={{ paddingLeft: "17px !important" }} className="para1">
                  Understanding your goals, target audience, and requirements to
                  lay a solid foundation for the project.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="d-flex  gap-2">
              <div>
                <img src={sec5dot2} alt="" />
              </div>
              <div>
                <p className="sec5title mb-0 mt-1">2.Strategy Formulation</p>
                <p style={{ paddingLeft: "17px !important" }} className="para1">
                  Transforming designs into functional websites with clean code
                  and seamless functionality.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="d-flex  gap-2">
              <div>
                <img src={sec5dot1} alt="" />
              </div>
              <div>
                <p className="sec5title mb-0 mt-1">3.Ongoing Optimization</p>
                <p style={{ paddingLeft: "17px !important" }} className="para1">
                  Deploying the website to the live environment and making it
                  accessible to your audience.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="col-lg-12">
          <img className="img-fluid" src={stagesbg} alt="" />
        </div>
      </div>
      <div className="container pt-4">
        <div className="row">
          <div className="col-lg-4">
            <div className="d-flex  gap-2">
              <div>
                <img src={sec5dot3} alt="" />
              </div>
              <div>
                <p className="sec5title mb-0 mt-1">4.Market Research</p>
                <p style={{ paddingLeft: "17px !important" }} className="para1">
                  Bringing your vision to life with captivating visuals and
                  intuitive user interfaces.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="d-flex  gap-2">
              <div>
                <img src={sec5dot4} alt="" />
              </div>
              <div>
                <p className="sec5title mb-0 mt-1">5.Content Creation</p>
                <p style={{ paddingLeft: "17px !important" }} className="para1">
                  Thoroughly checking every aspect of the website to ensure it
                  meets quality standards and performs flawlessly.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="d-flex  gap-2">
              <div>
                <img src={sec5dot3} alt="" />
              </div>
              <div>
                <p className="sec5title mb-0 mt-1">6.Reporting</p>
                <p style={{ paddingLeft: "17px !important" }} className="para1">
                  Providing ongoing support, maintenance, and updates to ensure
                  your website remains optimized and up to date.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="pt-5">
          <p className="sec2heading text-center mb-0 Seopara1">
            {" "}
            Expertise Across All Major Social{" "}
          </p>
          <p className="sec2heading text-center Seopara1"> Media Platforms </p>
          <div className="container">
            <div className="text-center pt-4">
              <img src={expertisesmm} alt="" className="img-fluid" />
            </div>
          </div>
        </div> */}

       

        <div className="mt-lg-5">
          <p className="sec2heading text-center mb-0 Seopara1 font-23">
            {" "}
            Fuel Your Success with Strategic {" "}
          </p>
          <p className="sec2heading text-center Seopara1 font-23 ">
            {" "}
            Social Media Campaigns {" "}
          </p>

          <div className="rocket  ">
            <div className="container">
              <div className="row align-items-center">
                <div
                  className="col-lg-6 col-md-6 col-sm-12 col-12  "
                  style={{
                   padding:'12% 0% 10% 8%'
                  }}
                >
                  <p className="rocket-text  text-white">
                    {" "}
                    Social media is more than just a trend; it's a necessity for businesses looking to thrive. Harnessing the power of social media can propel your brand to new heights, connecting you with a wider audience and driving meaningful engagement. 
                  </p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 col-12"></div>
              </div>
            </div>
          </div>
        </div>


        <div>
            <Testimonial1/>
          </div>

          <section className='mt-lg-4 mt-10'>
<div>
      <p className='text-center fw-600 fs-35 plus font-23'>Latest From The <span style={{ color: 'rgba(0, 83, 154, 1)' }}>Blog</span></p>
  </div>
  <div className='mt-4'>
  <Blog1 categoryId={categoryId}/>
  </div>
</section>
        {/* <div className="pt-4">
          <div>
            <p className="sec2heading text-center mb-0 Seopara1">
              {" "}
              What Our Happy Clients Are Saying {" "}
            </p>
          </div>
          <div className="pt-5">
            <Testimonial 
             paragraph="Datametricks breathed new life into our social media channels! Their content is not only visually stunning but also highly engaging. We've seen a significant increase in followers, likes, and shares, and most importantly, our conversion rates have improved. They've truly mastered the art of social media storytelling." title="Christy Yoland R." subtitle="CEO Founder Loker Indonesia"image={testimonialimage}
             paragraph1="We were struggling to gain traction on social media before we partnered with Datametricks. Their team developed a comprehensive social media strategy that perfectly aligned with our brand. They've helped us identify our target audience, optimize our campaigns, and track our results. We're now seeing consistent growth across all our social platforms."title1="Christy Yoland R." subtitle1="CEO Founder Loker Indonesia"image1={testimonialimage}
             paragraph2="Datametricks transformed our social media from a mere presence to a thriving community. They've facilitated meaningful conversations, fostered brand loyalty, and turned our followers into enthusiastic advocates for our products. We're so grateful for their expertise and dedication."title2="Christy Yoland R." subtitle2="CEO Founder Loker Indonesia"image2={testimonialimage}
             paragraph3="We were struggling to gain traction on social media before we partnered with Datametricks. Their team developed a comprehensive social media strategy that perfectly aligned with our brand. They've helped us identify our target audience, optimize our campaigns, and track our results. We're now seeing consistent growth across all our social platforms."title3="Christy Yoland R." subtitle3="CEO Founder Loker Indonesia"image3={testimonialimage}
             paragraph4="Datametricks transformed our social media from a mere presence to a thriving community. They've facilitated meaningful conversations, fostered brand loyalty, and turned our followers into enthusiastic advocates for our products. We're so grateful for their expertise and dedication."title4="Christy Yoland R." subtitle4="CEO Founder Loker Indonesia"image4={testimonialimage}

             />
          </div>
        </div> */}

        <div className="my-5   ">
          <Minicontact />
        </div>

        <div className="pb-5">
             <Faq  faqData={faqData} />
        </div>
      </div>

      <section>
  <div>
    <Chatbox/>
  </div>
</section>

      <div>
        <Footer />
      </div>
    </div>
  );
}
export default Webdev;
