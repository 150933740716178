// import React, { useState, useEffect } from 'react';

// const Blog1 = () => {
//   // const blogimg1 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/blogimg1.svg";


//   const [blogs, setBlogs] = useState([]);

//   useEffect(() => {

//     // Replace with your actual API URL
//     fetch('https://blog.datametricks.com/wp-json/wp/v2/posts')
//       .then((response) => response.json())
//       .then((data) => {
//         setBlogs(data.slice(0,3)); // Assuming data is an array of blog posts
//       })
//       .catch((error) => {
//         console.error('Error fetching the blog data:', error);
//       });
//   }, []);

//   return (
//     <div>
//       <section>
//         <div className='container'>
//           <p className='text-center fw-600 fs-35 plus font-23'>
//             Latest From The <span style={{ color: 'rgba(0, 83, 154, 1)' }}>Blog</span>
//           </p>
//           <div className='row justify-content-center mt-lg-4'>
//             {blogs.map((blog, index) => (
//               <div key={index} className='col-lg-3'>
//             <a href={blog.link} style={{textDecoration:'none'}}>  <div
//                   className='p-2 mb-3'
//                   style={{
//                     boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
//                     borderRadius: '8px',
//                   }}
//                 >
//                   <img
//                     src={blog.id.featured_media} 
//                   alt=''
//                     className='img-fluid'
//                   />
//               <p
//                     className="fw-700 fs-17 plus pt-2 font-23 textcenter single-line-title"
//                     style={{ color: 'rgba(51, 51, 51, 1)' }}
//                   >
//                   {blog.title.rendered}
//                   </p>
//                 </div></a>
//               </div>
//             ))}
//           </div>
//         </div>
//       </section>
//     </div>
//   );
// };

// export default Blog1;

// =========================================

import React, { useState, useEffect } from 'react';

const Blog1 = ({ categoryId }) => {

  const [blogs, setBlogs] = useState([]);
  const [featuredImages, setFeaturedImages] = useState({});

  useEffect(() => {
    if (!categoryId) return; // Do nothing if no category ID is provided

    // Fetch blog posts by category
    fetch(`https://blog.datametricks.com/wp-json/wp/v2/posts?categories=${categoryId}`)
      .then((response) => response.json())
      .then((data) => {
        setBlogs(data.slice(0, 3)); // Assuming data is an array of blog posts

        // Fetch featured images for each blog post
        data.slice(0, 3).forEach((blog) => {
          if (blog.featured_media) {
            fetch(`https://blog.datametricks.com/wp-json/wp/v2/media/${blog.featured_media}`)
              .then((res) => res.json())
              .then((media) => {
                setFeaturedImages((prevState) => ({
                  ...prevState,
                  [blog.id]: media.source_url,
                }));
              })
              .catch((error) => console.error('Error fetching featured image:', error));
          }
        });
      })
      .catch((error) => {
        console.error('Error fetching the blog data:', error);
      });
  }, [categoryId]); // Dependency on categoryId to refetch if it changes

  return (
    <div>

  <section>
        <div className='container'>
          <div className='row justify-content-center'>
            {blogs.map((blog) => (
              
              <div key={blog.id} className='col-lg-3 mb-3'>
                <a href={blog.link} style={{ textDecoration: 'none' }}>
                  <div
                    className='p-2 '
                    style={{
                      boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
                      borderRadius: '8px',
                    
                    }}
                  >
                    {featuredImages[blog.id] ? (
                      <img
                        src={featuredImages[blog.id]}
                        alt={blog.title.rendered}
                        className='img-fluid'
                      />
                    ) : (
                      <p>Loading image...</p>
                    )}
                    <p
                      className='fw-700 fs-17 plus pt-2 font-23 textcenter single-line-title'
                      style={{ color: 'rgba(51, 51, 51, 1)' }}
                    >
                      {blog.title.rendered}
                    </p>
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>
      </section>  



    </div>
  );
};

export default Blog1;
