import Footer from "../Components/footer";
import Header from "../Components/Header";
import Form from "../Components/Form";
import Accordion from 'react-bootstrap/Accordion';

const AiMl = () => {
const newhomeimg12 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/newhome-img12.svg";
const newhomeimg13 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/proimg1.svg";
const newhomeimg14 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/newhome-img14.svg";
const newhomeimg15 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/proimg2.svg";
const aimlimg1 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/aimlimg1.svg";
const aimlimg2 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/aimlimg3.svg";
const aimlimg4 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/aimlimg4.svg";
const aimlimg5 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/aimlimg5.svg";
const aimlimg6 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/aimlimg6.svg";
const aimlimg7 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/aimlimg7.svg";
const aimlimg8 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/aimlimg8.svg";
const aimlimg9 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/aimlimg9.svg";
const aimlimg10 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/aimlimg10.svg";
const aimlimg11 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/aimlimg11.svg";
    return(

        <div>
            <section>
                <Header/>
            </section>

            <section style={{marginTop:'5%'}}>
                <div className="pb-5" style={{background: 'linear-gradient(to right, rgba(22, 26, 86, 1),rgba(0, 0, 0, 1))',}}>
                    <div className="container-fluid">
                        <div className="row justify-content-center" >
                            <div className="col-lg-6 " >
                                <h1 className="plus fw-800 fs-40 font-23 lineheight-35 pt80 " style={{color:'rgba(255, 255, 255, 1)',lineHeight:'50px',paddingTop:'110px'}}>Harness the Power of AI & ML with Custom Website Development Solutions</h1>
                                <p className="fs-17 fw-400 plus pt-4 font-18" style={{color:'rgba(255, 255, 255, 0.6)'}}>Harness the potential of AI and ML to automate processes, enhance decision-making, and drive business growth. Our custom solutions are designed to optimize efficiency and deliver intelligent insights for smarter operations.</p>
                                <div>
                                <a href='/contact-us'>
        <button
          type="button"
          className="btn btn-sm px-5 mt-4 pb-0 btn1"
          style={{
            border: 'none',
            outline: 'none',
            boxShadow: 'none',
            backgroundColor: 'rgba(0, 110, 205, 1)',
            borderRadius: '42px',
          }}
        >
          <p className="fs-18  fw-600 lexend text-white pt-2">
            Contact us
          </p>
        </button></a>
                                </div>
                            </div>
                            <div className="col-lg-5 ">
                                <img src={aimlimg1} className="img-fluid pt-20" alt="aiml-img" style={{paddingTop:'45px'}}/>
                                
                                </div>
                        </div>
                    </div>

                </div>
            </section>


<section>
    <div className="aimlbg1 d-flex flex-wrap justify-content-center">
        <div
            className="p-4 aiml-box mt0"
            style={{
                border: '0.6px solid rgba(255, 255, 255, 0.52)',
                borderRadius: '19px',
                backdropFilter: 'blur(10px)',
                width: '100%',
                maxWidth: '650px',
                marginTop:'21%',
                backgroundColor: 'rgba(0, 0, 0, 0.26)',
            }}
        >
            <p
                className="fw-600 fs-35 plus font-23 mb-0"
                style={{ color: 'rgba(255, 255, 255, 1)' }}
            >
               AI & ML-Driven Website  
            </p>
            <p
                className="fw-600 fs-35 plus font-23"
                style={{ color: 'rgba(255, 255, 255, 1)' }}
            >
               Solutions 
            </p>
            <p
                className="fw-400 fs-17 plus font-18"
                style={{ color: 'rgba(255, 255, 255, 0.6)' }}
            >
               Our customized AI and ML solutions help businesses create personalized user experiences, leverage predictive analytics, and automate key processes. With our intelligent platforms, your business can make smarter, data-driven decisions, streamline operations, and gain a competitive advantage in the digital landscape.
            </p>
        </div>

        <div
            className="p-4 aiml-box mt0 ml0 "
            style={{
                border: '0.6px solid rgba(255, 255, 255, 0.52)',
                borderRadius: '19px',
                backdropFilter: 'blur(10px)',
                width: '100%',
                maxWidth: '500px',
                marginTop: '19%',
                marginLeft:'20px',
                backgroundColor: 'rgba(0, 0, 0, 0.26)',
            }}
        >
            <p
                className="fw-600 fs-35 plus font-23"
                style={{ color: 'rgba(255, 255, 255, 1)' }}
            >
               Benefits of AI and ML Integration
            </p>
            <p
                className="fw-400 fs-17 plus font-18"
                style={{ color: 'rgba(255, 255, 255, 0.6)' }}
            >
               By integrating AI and ML, your website becomes more scalable, adaptable, and capable of handling growing demands. These technologies enable real-time data analysis, delivering insights that improve decision-making and enhance the overall user experience. Stay ahead of the competition with cutting-edge AI-driven innovations designed to boost efficiency and drive business growth.
            </p>
            {/* <ul
                className="fw-400 fs-17 plus font-18"
                style={{ color: 'rgba(255, 255, 255, 0.6)' }}
            >
                <li>Automated workflows</li>
                <li className="pt-2">Scalability and flexibility</li>
                <li className="pt-2">Data-driven decision making</li>
                <li className="pt-2">Predictive analytics and recommendations</li>
                <li className="pt-2"> Enhanced user experiences with personalization</li>
            </ul> */}
        </div>
    </div>
</section>

<section >
    <div style={{backgroundColor:'black'}}>
        <div className="container pt-4 pb-5">
            <p className="fw-600 fs-32 plus font-23 text-center" style={{color:'rgba(255, 255, 255, 1)'}}>Our AI & ML Website Development Services</p>
            <div className="row justify-content-center">
                <div className="col-lg-10 gradient-background-aiml p-5" >
                
                    <div className="row">
                        <div className="col-md-7">
                            <p className="fw-300 fs-22 plus pt-lg-4" style={{color:'rgba(255, 255, 255, 1)'}}>Our AI and ML services cover a wide range of industries and use cases. We deliver  intelligent and adaptive website solutions to meet your business needs.</p>

                        </div>
                        <div className="col-md-5">
                            <img src={aimlimg10} alt="" className="img-fluid pt-10" />
                        </div>
                    </div>
                

                </div>
                <div className="col-lg-10 p-4" style={{border:'0.6px solid rgba(255, 255, 255, 1)',borderBottomRightRadius:'15px',borderBottomLeftRadius:'15px',borderTop:'none'}} >
                
                <div className="row ">
                    <div className="col-md-4">
                        <div className="d-flex gap-3">
                            <div>
                                <img src={aimlimg11} alt="" />
                            </div>
                            <div>
                                <p style={{color:'rgba(255, 255, 255, 0.6)'}}>01.</p>
                                <p className="fw-600 fs-25 plus font-23" style={{color:'rgba(255, 255, 255, 1)'}}>AI-Powered Personalization</p>
                                <p className="fs-17 plus" style={{color:'rgba(255, 255, 255, 0.6)'}}>Create tailored user experiences based on behavior, preferences, and  data patterns.</p>
                            </div>
                            <div>

                            </div>
                        </div>

                    </div>
                    <div className="col-md-4">
                        <div className="d-flex gap-3">
                            <div>
                                <img src={aimlimg11} alt="" />
                            </div>
                            <div>
                                <p style={{color:'rgba(255, 255, 255, 0.6)'}}>02.</p>
                                <p className="fw-600 fs-25 plus font-23" style={{color:'rgba(255, 255, 255, 1)'}}>Predictive Analytics & Data Processing</p>
                                <p className="fs-17 plus" style={{color:'rgba(255, 255, 255, 0.6)'}}>Leverage machine learning models to analyze trends and provide  predictive insights.</p>
                            </div>
                            <div>

                            </div>
                        </div>

                    </div>

                    <div className="col-md-4">
                        <div className="d-flex gap-3">
                            <div>
                                <img src={aimlimg11} alt="" />
                            </div>
                            <div>
                                <p style={{color:'rgba(255, 255, 255, 0.6)'}}>03.</p>
                                <p className="fw-600 fs-25 plus font-23" style={{color:'rgba(255, 255, 255, 1)'}}>Chatbots & AI-driven Customer Support</p>
                                <p className="fs-17 plus" style={{color:'rgba(255, 255, 255, 0.6)'}}>Integrate conversational AI tools to enhance customer support and  automate FAQs.</p>
                            </div>
                            <div>

                            </div>
                        </div>

                    </div>
                </div>
            

            </div>
            </div>
        </div>
    </div>
</section>

<section >
    <div className="container-fluid pb-5 pt-2" style={{backgroundColor:'rgba(17, 17, 17, 1)'}}>
        <div className="row ">
            <div className="col-lg-12 text-center">
                <p className="fw-600 fs-35 plus font-23" style={{color:'rgba(255, 255, 255, 1)'}}>Our AI & ML Development Process</p>
                <img src={aimlimg2} className="img-fluid mt-lg-5 mt-20" alt="" />
            </div>

        </div>

    </div>
</section>

<section>
    <div className="container-fluid pb-20" style={{backgroundColor:'black'}}>
        <p className="fw-600 fs-35 plus text-center font-23 pt-2" style={{color:'rgba(255, 255, 255, 1)'}}>Industry-Specific AI & ML Solutions</p>
        <div className="row justify-content-center mt-lg-5 mt-30" >
            <div className="col-lg-10" >
                <div className="row justify-content-center " >
                    <div className="col-md-5">
                        <div className="d-flex gap-lg-4 gap-14 p-2 " style={{border:'1px solid rgba(105, 26, 137, 1)',borderRadius:'12px'}}>
                            <div>
                                 <img src={aimlimg4} className="" alt="" />
                            </div>
                            <div>
                               <h1 className="fw-700 fs-20 plus newfont-20 " style={{color:'rgba(255, 255, 255, 1)'}}>E-commerce</h1>
                               <p className="fs-16 plus fw-400 newfont-13" style={{color:'rgba(255, 255, 255, 0.6)'}}>AI-driven product recommendations, personalized shopping experiences.</p>
                            </div>
                        </div>

                    </div>
                    <div className="col-md-5 mt-20">
                    <div className="d-flex gap-lg-4 gap-14 p-2" style={{border:'1px solid rgba(63, 38, 106, 1)',borderRadius:'12px'}}>
                            <div>
                                 <img src={aimlimg5} className="" alt=""/>
                            </div>
                            <div>
                               <h1 className="fw-700 fs-20 plus newfont-20" style={{color:'rgba(255, 255, 255, 1)'}}>Healthcare</h1>
                               <p className="fs-16 plus fw-400 newfont-13" style={{color:'rgba(255, 255, 255, 0.6)'}}>Predictive health analytics, patient engagement solutions.</p>
                            </div>
                        </div>
                        
                    </div>

                    <div className="col-md-5 mt-4">
                        <div className="d-flex gap-lg-4 gap-14 p-2" style={{border:'1px solid rgba(61, 42, 114, 1)',borderRadius:'12px'}}>
                            <div>
                                 <img src={aimlimg6} className="" alt=""/>
                            </div>
                            <div>
                               <h1 className="fw-700 fs-20 plus newfont-20" style={{color:'rgba(255, 255, 255, 1)'}}>Finance</h1>
                               <p className="fs-16 plus fw-400 newfont-13" style={{color:'rgba(255, 255, 255, 0.6)'}}>Fraud detection, automated financial insights, data-driven decision-making.</p>
                            </div>
                        </div>

                    </div>
                    <div className="col-md-5 mt-4">
                    <div className="d-flex gap-lg-4 gap-14 p-2" style={{border:'1px solid rgba(47, 49, 165, 1)',borderRadius:'12px'}}>
                            <div>
                                 <img src={aimlimg7} className="" alt=""/>
                            </div>
                            <div>
                               <h1 className="fw-700 fs-20 plus newfont-20" style={{color:'rgba(255, 255, 255, 1)'}}>Retail</h1>
                               <p className="fs-16 plus fw-400 newfont-13" style={{color:'rgba(255, 255, 255, 0.6)'}}>Inventory management, AI-powered customer support.</p>
                            </div>
                        </div>
                        
                    </div>

                    <div className="col-md-5 mt-4">
                        <div className="d-flex gap-lg-4 gap-14 p-2" style={{border:'1px solid rgba(61, 42, 114, 1)',borderRadius:'12px'}}>
                            <div>
                                 <img src={aimlimg8} className="" alt="" />
                            </div>
                            <div>
                               <h1 className="fw-700 fs-20 plus newfont-20" style={{color:'rgba(255, 255, 255, 1)'}}>Real Estate</h1>
                               <p className="fs-16 plus fw-400 newfont-13" style={{color:'rgba(255, 255, 255, 0.6)'}}>Property recommendation systems, market analysis tools.</p>
                            </div>
                        </div>

                    </div>
                    <div className="col-md-5 mt-4">
                    <div className="d-flex gap-lg-4 gap-14 p-2" style={{border:'1px solid rgba(61, 42, 114, 1)',borderRadius:'12px'}}>
                            <div>
                                 <img src={aimlimg9} className="" alt=""/>
                            </div>
                            <div>
                               <h1 className="fw-700 fs-20 plus newfont-20" style={{color:'rgba(255, 255, 255, 1)'}}>Education</h1>
                               <p className="fs-16 plus fw-400 newfont-13" style={{color:'rgba(255, 255, 255, 0.6)'}}>Advance medical industry by offering more precise diagnosis, tailored therapies, and efficient operations.</p>
                            </div>
                        </div>
                        
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>


   
<section>
      <div className='pb-5 pt-lg-5  p-20 ' style={{backgroundColor:'black'}}>
       <div className='container' >
        <p className="fw-600 fs-35 plus font-23 text-center textcenter" style={{color:'rgba(255, 255, 255, 1)'}}>AI & ML Success Stories</p>
        <div className="py-lg-5 " style={{backgroundColor:'rgba(23, 23, 23, 1)',borderRadius:'25px'}}>
           <div className='row justify-content-center'>
            <div className='col-lg-4'>
          <a href='/our-completed-projects/lookplex'>    <div className='hovercontainer  '>
                <img src={newhomeimg12} alt='' className='img-fluid image123  ' />
                <div class="overlay123">
                     <div class="text123">Salon at Home Services Website</div>
                     <div class="text1234">For a salon at home services provider, we designed a sleek and modern website with an easy
                          booking feature. The site allows customers to book appointments effortlessly and explore various
                            beauty services offered.</div>
                 </div>
               </div></a>
            </div>
            <div className='col-lg-4'>
            <a href='/our-completed-projects/smartcare'> <div className='hovercontainer  '>
                <img src={newhomeimg13} alt='' className='img-fluid pt-20 image123  ' />
                <div class="overlay123">
                     <div class="text123">Smartcare</div>
                     <div class="text1234">We created a healthcare platform, SmartCare, that enables patients to easily book appointments, find doctors, store medical records, and save vital health information, streamlining their healthcare experience.</div>
                 </div>
               </div></a>

            </div>
           </div>
           <div className='row justify-content-center mt-4'>
            <div className='col-lg-4'>
          <a href='/our-completed-projects/monovouge'>  <div className='hovercontainer  '>
                <img src={newhomeimg14} alt='' className='img-fluid mt0 image123  ' />
                <div class="overlay123">
                     <div class="text123 "> E-commerce Platform</div>
                     <div class="text1234">We developed a dynamic and visually appealing e-commerce platform that enhances the
shopping experience. The site includes secure payment gateways, intuitive navigation, and a
responsive design for seamless browsing on all devices.</div>
                 </div>
               </div></a>

            </div>
            <div className='col-lg-4 hidepara'>
            <a href='/our-completed-projects/healthomax-website'> <div className='hovercontainer'>
                <img src={newhomeimg15} alt='' className='img-fluid mt0 image123  ' />
                <div class="overlay123">
                     <div class="text123"> Healthomax</div>
                     <div class="text1234">We developed the HealthOmax mobile application for both Android and iOS platforms. The app offers the same features as the website, allowing medical professionals to connect and collaborate, and patients to interact with doctors on the go.</div>
                 </div>
               </div></a>

            </div>
           </div> 
       </div>
       </div>
       </div>
    </section>  


    <section>
        <div className='aiml-bg1 aimlresponse-bg1'>  
  <div className='container'>
    <div className='row justify-content-center'>
      <div className='col-lg-7 col-md-6 col-12 textcenter '>
          <h2 className='fs-40 fw-800 plus  font-25 mt0 pt-10' style={{marginTop:'200px',color:'rgba(217, 217, 217, 1)'}}>Ready to Leverage AI & ML for Your Business?</h2>
          <p className='fs-18 fw-400 plus font-17' style={{color:'rgba(255, 255, 255, 0.6)'}}>Our tailored AI solutions help you automate processes, make data-driven decisions, and stay ahead of the competition. Start your digital transformation journey today</p>
      </div>
      <div className='col-lg-4 col-md-6 col-12'>
         <div>
          <Form/>
         </div>
        </div>
    </div>
  </div>
  </div>
  
</section>


<section>
    <div className="container-fluid pb-5" style={{backgroundColor:'black'}}>
        <p className="fw-600 fs-35 plus text-center pt-4 font-23" style={{color:'rgba(255, 255, 255, 1)'}}>Frequently asked questions</p>
        <p className="fw-400 fs-16 plus text-center font-18" style={{color:'rgba(150, 150, 150, 1)'}}>We provides answers to common questions about our products/services.</p>
        <div className="row justify-content-center">
            <div className="col-lg-8 mt-4">

             <Accordion defaultActiveKey="0" >
      <Accordion.Item eventKey="0" >
        <Accordion.Header className="fw-500 fs-18 plus" style={{color:'rgba(34, 34, 34, 1)',borderBottom:'none'}}> 1. What is Artificial Intelligence (AI) and Machine Learning (ML)?</Accordion.Header>
        <Accordion.Body>
        AI refers to the simulation of human intelligence in machines that are programmed to think and learn like humans. ML is a subset of AI that enables systems to learn from data and improve their performance over time without being explicitly programmed.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1" className="" >
        <Accordion.Header className="fw-500 fs-18 plus " style={{color:'rgba(34, 34, 34, 1)'}}>2. How can AI and ML benefit my business?</Accordion.Header>
        <Accordion.Body>
        AI and ML can automate repetitive tasks, improve decision-making through data analysis, enhance customer experiences, and drive innovation in your business. They can also optimize operational efficiency, reduce costs, and help you gain a competitive edge.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2" className="">
        <Accordion.Header className="fw-500 fs-18 plus" style={{color:'rgba(34, 34, 34, 1)'}}>3. What industries can benefit from AI & ML solutions?</Accordion.Header>
        <Accordion.Body>
        AI & ML solutions can be applied across various industries, including healthcare, retail, finance, manufacturing, real estate, and more. Any business looking to optimize processes or leverage data for better decision-making can benefit from AI & ML.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3" className="">
        <Accordion.Header className="fw-500 fs-18 plus" style={{color:'rgba(34, 34, 34, 1)'}}>4. How long does it take to implement an AI & ML solution?</Accordion.Header>
        <Accordion.Body>
        The implementation timeline depends on the complexity of your project. A small-scale AI solution may take a few weeks, while more complex systems, such as custom ML models, can take several months to develop and deploy.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4" className="">
        <Accordion.Header className="fw-500 fs-18 plus" style={{color:'rgba(34, 34, 34, 1)'}}>5. What kind of data is required for Machine Learning models?</Accordion.Header>
        <Accordion.Body>
        
            ML models typically require large datasets to train on. The type of data depends on the problem you are solving—it could be structured data (like sales records) or unstructured data (like images or text). We’ll work with you to assess and prepare the right data for your project.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="5" className="">
        <Accordion.Header className="fw-500 fs-18 plus" style={{color:'rgba(34, 34, 34, 1)'}}>6. Do I need a large budget to implement AI & ML solutions?</Accordion.Header>
        <Accordion.Body>
        
        AI & ML solutions can be tailored to fit various budgets. We offer scalable solutions depending on the size and scope of your project, ensuring you get the maximum value from your investment.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="6" className="">
        <Accordion.Header className="fw-500 fs-18 plus" style={{color:'rgba(34, 34, 34, 1)'}}>7. How can I get started with AI & ML for my business?</Accordion.Header>
        <Accordion.Body>
        
        Simply reach out to us for a free consultation. We'll assess your business needs, data readiness, and help you identify the best AI and ML strategies to improve your business performance.
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
                 



            </div>
        </div>
    </div>
</section>

            <section>
                <Footer/>
            </section>

        </div>

    );

};
export default AiMl;