import Header from "../Components/Header";

function Successemailpage (){
    const successemail = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/successemail.webp";


    
return(
    <div>
        <div>
            <Header/>
        </div>
        <div className="mt-100" style={{marginTop:'8%'}}>
        <p className="fs-50 fw-600 text-center newfont-33" style={{color:'green'}}>Thank you for connect with us</p>
        </div>
        <div className="text-center">
            <img src={successemail} alt="" className="img-fluid height-300" style={{height:'530px'}}/>
        </div>
       




      

     
     
    </div>
)
}
export default Successemailpage;