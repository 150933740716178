
import React, { useState, useEffect } from 'react';
import Header from '../Components/Header';
import Footer from '../Components/footer';
import Bigcontact from '../Components/Bigcontact';
import Minicontact from '../Components/Minicontact';
import Faq from '../Components/Faq';
import Testimonial1 from '../Components/Testimonial1';
import MobdevCard from '../Components/MobdevCard';
import Chatbox from '../Components/Chatbox';
import SEOPage from '../Components/SEOPage';
import Blog1 from '../Components/Blog1';
import { Helmet } from "react-helmet";


function Mobiledev() {

    const [categories, setCategories] = useState([]);

    useEffect(() => {
      fetch('https://blog.datametricks.com/wp-json/wp/v2/categories')
        .then((response) => response.json())
        .then((data) => setCategories(data))
        .catch((error) => console.error('Error fetching categories:', error));
    }, []);
  
   
    const categoryId=14;
  

    
    const serviceSchemaData2 = 
    [
    {
        "@context": "https://schema.org",
        "@type": "Service",
        "serviceType": "Mobile App Design and Development",
        "provider": {
          "@type": "Organization",
          "name": "Datametricks Consulting Pvt Ltd",
          "url": "https://www.datametricks.com/",
          "logo": "https://d3vqz9qyn2r1xe.cloudfront.net/imges/comlogo.svg",
          "sameAs": [
            "https://www.facebook.com/people/DataMetricks-Consulting-PvtLtd/61554350043470/",
            "https://www.instagram.com/datametricks_pvt_ltd/",
            "https://x.com/datametricks",
            "https://www.linkedin.com/company/datametricks-consulting-pvt-ltd/"
          ],
          "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+91 7303946657",
            "contactType": "Customer Service",
            "email": "contact@datametricks.com"
          },
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "A40, ITHUM TOWER, Industrial Area, Sector 62",
            "addressLocality": "Noida",
            "addressRegion": "Uttar Pradesh",
            "postalCode": "201309",
            "addressCountry": "IN"
          }
        },
        "areaServed": {
          "@type": "Place",
          "name": "India"
        },
        "description": "Datametricks offers expert mobile app design and development services for iOS and Android platforms. Our services include custom app solutions, UI/UX design, and mobile optimization to help businesses enhance their mobile presence and engage users.",
        "offers": {
          "@type": "Offer",
          "url": "https://www.datametricks.com/service/mobile-app-design-development",
          "priceCurrency": "INR",
          "eligibleRegion": {
            "@type": "Place",
            "name": "India"
          },
          "availability": "https://schema.org/InStock",
          "validThrough": "2024-12-31"
        }
      }
    ];
    
const mobdevbg = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/mobdevsec1bg.png';
const sec5dot1 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/sec5dot1.png'
const sec5dot2 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/sec5dot2.png'
const sec5dot3 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/sec5dot3.png'
const sec5dot4 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/sec5dot4.png'
const stagesbg = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/stagesbg.png'
const expertise = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/expertisebg.png'
const lever = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/leverbg.png'

// const testimonialimage = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/testimonial-img.png";
const mobnewimg1 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/mobnewimg1.png";
const mobnewimg2 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/mobnewimg2.png";
const mobnewimg3 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/mobnewimg3.png";
const mobnewimg4 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/mobnewimg4.png";
const mobnewimg5 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/mobnewimg5.png";
const mobnewimg6 = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/mobnewimg6.png";
const mobnewbgimg = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/mobnewbgimg.png";


    var faqData=[
        {que:"1. What is mobile app development and why is it important?",ans:"Mobile app development involves creating software applications that run on mobile devices. It's important because mobile apps can enhance customer engagement, provide better user experiences, and drive business growth by making your services accessible anytime, anywhere." },
        {que:"2. How long does it take to develop a mobile app? ", ans:"The timeline for developing a mobile app varies depending on the complexity and features of the app. A basic app might take a few months, while a more complex app with advanced features could take six months or longer."},
        {que:"3. How much does it cost to develop a mobile app? ",ans:"The cost of developing a mobile app depends on various factors, including the app's complexity, features, design, and the development team's rates. It's best to discuss your specific requirements with a development team to get an accurate estimate."},
        {que:"4. What is the difference between native and cross-platform app development? ",ans:"Native app development involves creating apps specifically for one platform (iOS or Android) using platform-specific languages and tools. Cross-platform app development allows you to create apps that run on multiple platforms (iOS and Android) using a single codebase, saving time and resources. "},
        {que:"5. How do you ensure the security of my mobile app? ",ans:"We prioritize app security by implementing best practices such as data encryption, secure APIs, regular security testing, and compliance with industry standards. Our team stays updated on the latest security trends to protect your app from vulnerabilities. "},
        {que:"6. Can you integrate my mobile app with existing systems? ",ans:"Yes, we offer app integration services that connect your mobile app with existing systems, databases, and third-party services to ensure seamless functionality and enhanced capabilities. "},
        {que:"7. What kind of post-launch support do you provide?",ans:"We provide comprehensive post-launch support, including regular updates, bug fixes, performance optimization, and ongoing maintenance to ensure your app remains functional and up to date. " },
        {que:"8. How do you handle app updates and maintenance? ",ans:"We offer ongoing maintenance services to keep your app updated with the latest features and security patches. Our team monitors the app's performance and user feedback to make necessary improvements and ensure optimal functionality. " },
        {que:"9. Can you help with app store submissions? ",ans:"Yes, we assist with the entire app store submission process, including preparing necessary documentation, ensuring compliance with app store guidelines, and managing the submission to both Apple App Store and Google Play Store. "},
        {que:"10. Why should I choose Datametricks for my mobile app development? ",ans:"Datametricks Consulting offers expert mobile app development services tailored to your business needs. Our team has extensive experience, a proven track record of successful projects, and a commitment to delivering high-quality, user-centric apps. We provide end-to-end services, from initial strategy and design to development, testing, and ongoing support. "},    
  ];

    return (
        <div >
             <SEOPage 
                title="Custom Mobile App Design & Development Services | iOS & Android Solutions - Datametricks"
                description="Datametricks offers custom mobile app design and development services for iOS and Android
platforms. From UI/UX design to robust app solutions, we deliver apps tailored to your business
needs. Get your custom mobile app today!"
                canonicalUrl="https://www.datametricks.com/service/mobile-app-design-development-solutions"  />

      <Helmet>
             <script type="application/ld+json">
                  {JSON.stringify(serviceSchemaData2)}
             </script>
      </Helmet>
       
            <Header />

            {/* section 1 */}
            <section>
                <div className='pb-5' style={{ backgroundImage: `url(${mobdevbg})`, backgroundRepeat: "no-repeat", height: 'fit-content', backgroundSize: "cover", backgroundPosition: "100% 100%" }}>
                    <div style={{ paddingTop: '150px' }} className="container padtop">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <p className='heading Seopara1 font-23'>Transform Your Ideas into Reality With Our Mobile App Development Services </p>
                                <p className='para Seopara3 font-18'> Creating Innovative and User-Friendly Mobile Apps for iOS and Android.  </p>
                            </div>
                            <div className="col-lg-1">

                            </div>
                            <div className="col-lg 5">
                               <Bigcontact/>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            {/* section 1 end */}

            {/* section 2 */}
            <section>
                <div className='sec2 pt-lg-5 mt-10 pb-4'>
                    <p className='sec2heading text-center mb-0 Seopara1 font-23'> Power Your Business with Custom Mobile App Solutions  </p>
                
                 <div>
                         <MobdevCard/>
                 </div>       
                </div>
            </section>

            <section>
                <div className='sec2 mt-lg-4 pb-lg-5'>
                    <p className='sec2heading text-center mb-0 Seopara1 font-23'> Why Choose Datametricks for Your Mobile App    </p>
                    <p className='sec2heading text-center Seopara1 font-23'> Development Needs  </p>
                    <p className='text-center mb-0 mt-3 font-18' style={{fontSize:'18px', fontWeight:'400', fontFamily:"Plus Jakarta Sans, sans-serif", }}> We pride ourselves on delivering high-quality mobile app development services that meet the  </p>
                    <p className='text-center font-18' style={{fontSize:'18px', fontWeight:'400', fontFamily:"Plus Jakarta Sans, sans-serif", }}>unique needs of our clients. Here’s why businesses choose us as their trusted partner:</p>
                    <div className="container mt-lg-4 text-center">
                    <img src={mobnewbgimg} alt='' className='img-fluid'/>
  
                    </div>
                </div>
            </section>

            <section>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-lg-4'> 
                            <img src={mobnewimg1} alt='' className='img-fluid '/>
                        </div>
                        <div className='col-lg-6 '> 
                        <div className=' my-lg-5 mx-lg-4 mt-10 textcenter'>
                            <h1 className='font-23' style={{fontSize:'23px', fontWeight:'500', fontFamily:"Plus Jakarta Sans, sans-serif", }}>Expertise and Experience</h1>
                            <p className='font-18' style={{fontSize:'16px', fontWeight:'400', fontFamily:"Plus Jakarta Sans, sans-serif", }}> Our team consists of seasoned developers and designers with extensive experience in creating innovative mobile apps for various industries. We stay updated with the latest trends and technologies to ensure your app is cutting-edge and competitive. </p>
                        </div>
                        </div>
                      
                    </div>
                </div>

                <div className='container mt-lg-5'>
                    <div className='row justify-content-center'>

                    <div className='col-lg-6 '> 
                        <div className=' my-lg-5 mx-lg-4 mt-10 textcenter'>
                            <h1 className='font-23' style={{fontSize:'23px', fontWeight:'500', fontFamily:"Plus Jakarta Sans, sans-serif", }}>Customized Solutions</h1>
                            <p className='font-18' style={{fontSize:'16px', fontWeight:'400', fontFamily:"Plus Jakarta Sans, sans-serif", }}> We understand that every business has different requirements. Our solutions are tailored to your specific goals, ensuring that your mobile app aligns perfectly with your business strategy and delivers the desired results.  </p>
                        </div>
                        </div>

                        <div className='col-lg-4'> 
                            <img src={mobnewimg2} alt='' className='img-fluid  '/>
                        </div>
                      
                      
                    </div>
                </div>

                <div className='container mt-lg-5 mt-10'>
                    <div className='row justify-content-center'>
                        <div className='col-lg-4'> 
                            <img src={mobnewimg3} alt='' className='img-fluid '/>
                        </div>
                        <div className='col-lg-6 '> 
                        <div className=' my-lg-5 mx-lg-4 mt-10 textcenter'>
                            <h1 className='font-23' style={{fontSize:'23px', fontWeight:'500', fontFamily:"Plus Jakarta Sans, sans-serif", }}>User-Centric Design</h1>
                            <p className='font-18' style={{fontSize:'16px', fontWeight:'400', fontFamily:"Plus Jakarta Sans, sans-serif", }}> We prioritize the user experience in every project. Our design approach focuses on creating intuitive, engaging, and user-friendly interfaces that keep users coming back to your app.  </p>
                        </div>
                        </div>
                      
                    </div>
                </div>

                <div className='container mt-lg-5'>
                    <div className='row justify-content-center'>

                    <div className='col-lg-6 '> 
                        <div className=' my-lg-5 mx-lg-4 mt-10 textcenter'>
                            <h1 className='font-23' style={{fontSize:'23px', fontWeight:'500', fontFamily:"Plus Jakarta Sans, sans-serif", }}>Comprehensive Services</h1>
                            <p className='font-18' style={{fontSize:'16px', fontWeight:'400', fontFamily:"Plus Jakarta Sans, sans-serif", }}> From initial strategy and consulting to design, development, testing, and maintenance, we offer end-to-end services to ensure your app is successful from concept to launch and beyond.   </p>
                        </div>
                        </div>

                        <div className='col-lg-4'> 
                            <img src={mobnewimg4} alt='' className='img-fluid  '/>
                        </div>
                      
                      
                    </div>
                </div>

                <div className='container mt-lg-5 mt-10'>
                    <div className='row justify-content-center'>
                        <div className='col-lg-4'> 
                            <img src={mobnewimg5} alt='' className='img-fluid '/>
                        </div>
                        <div className='col-lg-6 '> 
                        <div className=' my-lg-5 mx-lg-4 mt-10 textcenter'>
                            <h1 className='font-23' style={{fontSize:'23px', fontWeight:'500', fontFamily:"Plus Jakarta Sans, sans-serif", }}>Ongoing Support</h1>
                            <p className='font-18' style={{fontSize:'16px', fontWeight:'400', fontFamily:"Plus Jakarta Sans, sans-serif", }}> Our commitment doesn’t end with the app launch. We provide ongoing support and maintenance to keep your app running smoothly and efficiently, ensuring it stays relevant and functional in the ever-evolving digital landscape. </p>
                        </div>
                        </div>
                      
                    </div>
                </div>

                <div className='container mt-lg-5'>
                    <div className='row justify-content-center'>

                    <div className='col-lg-6 '> 
                        <div className=' my-lg-5 mx-lg-4 mt-10 textcenter'>
                            <h1 className='font-23' style={{fontSize:'23px', fontWeight:'500', fontFamily:"Plus Jakarta Sans, sans-serif", }}>Proven Track Record</h1>
                            <p className='font-18' style={{fontSize:'16px', fontWeight:'400', fontFamily:"Plus Jakarta Sans, sans-serif", }}> We have a history of successful projects and satisfied clients who have benefited from our expertise. Our portfolio showcases a range of apps that have helped businesses achieve their digital goals.    </p>
                        </div>
                        </div>

                        <div className='col-lg-4'> 
                            <img src={mobnewimg6} alt='' className='img-fluid '/>
                        </div>
                      
                      
                    </div>
                </div>
            </section>


            <div style={{marginTop:'5%'}}>
                        <p className='sec2heading text-center mb-0 font-23'> Our Proven Process for a Successful </p>
                        <p className='sec2heading text-center font-23'> App Launch </p>
                    </div>
                    <div className="container pt-lg-4 ">
                        <div className="row ms-lg-5">
                            <div className="col-lg-4">
                                <div className='d-flex  gap-2'>
                                    <div>
                                        <img src={sec5dot1} alt="" />
                                    </div>
                                    <div>
                                        <p className='sec5title mb-0 mt-1'>1.Discovery and Planning</p>
                                        <p style={{ paddingLeft: '17px !important' }} className='para1 pt-2'>Understanding your goals, target audience, and requirements to  lay a solid foundation for the project.</p>
                                    </div>
                                </div>

                            </div>
                            <div className="col-lg-4">
                                <div className='d-flex  gap-2'>
                                    <div>
                                        <img src={sec5dot2} alt="" />
                                    </div>
                                    <div>
                                        <p className='sec5title mb-0 mt-1'>2.Development  </p>
                                        <p style={{ paddingLeft: '17px !important' }} className='para1 pt-2'> Transforming designs into functional websites with clean code and  seamless functionality.</p>
                                    </div>
                                </div>

                            </div>
                            <div className="col-lg-4">
                                <div className='d-flex  gap-2'>
                                    <div>
                                        <img src={sec5dot1} alt="" />
                                    </div>
                                    <div>
                                        <p className='sec5title mb-0 mt-1'>3.Launch</p>
                                        <p style={{ paddingLeft: '17px !important' }} className='para1 pt-2'> Deploying the website to the live environment and making it accessible to your  audience.</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="col-lg-12">
                            <img className='img-fluid ms-lg-5' src={stagesbg} alt="" />
                        </div>
                    </div>
                    <div className="container pt-4">
                        <div className="row ms-lg-5">
                            <div className="col-lg-4">
                                <div className='d-flex  gap-2'>
                                    <div>
                                        <img src={sec5dot3} alt="" />
                                    </div>
                                    <div>
                                        <p className='sec5title mb-0 mt-1'>4.design</p>
                                        <p style={{ paddingLeft: '17px !important' }} className='para1 pt-2'>Bringing your vision to life with captivating visuals and intuitive user interfaces.</p>
                                    </div>
                                </div>

                            </div>
                            <div className="col-lg-4">
                                <div className='d-flex  gap-2'>
                                    <div>
                                        <img src={sec5dot4} alt="" />
                                    </div>
                                    <div>
                                        <p className='sec5title mb-0 mt-1'>5.Testing </p>
                                        <p style={{ paddingLeft: '17px !important' }} className='para1 pt-2'>Thoroughly checking every aspect of the website to ensure it meets quality  standards and performs flawlessly.</p>
                                    </div>
                                </div>

                            </div>
                            <div className="col-lg-4">
                                <div className='d-flex  gap-2'>
                                    <div>
                                        <img src={sec5dot3} alt="" />
                                    </div>
                                    <div>
                                        <p className='sec5title mb-0 mt-1'>6.Beyond</p>
                                        <p style={{ paddingLeft: '17px !important' }} className='para1 pt-2'>Providing ongoing support, maintenance, and updates to ensure your  website remains optimized and up to date.</p>
                                    </div>
                                </div>

                            </div>
                        </div>


                        <div className='pt-lg-5 mt-10'>
                        <p className='sec2heading text-center mb-0 Seopara1 '> Complete Mobile App Solutions:   </p>
                        <p className='sec2heading text-center Seopara1 font-23'> iOS, Android, and Hybrid Expertise </p>
                        <div className="container">
                            <div className="row align-items-center justify-content-center">
                                <div className="col-lg-4">
                                    <img style={{ maxWidth: '100%' }} src={expertise} alt="" className='mt-lg-3' />
                                </div>
                                <div className="col-lg-7">
                                    <p className='para1 font-18 textcenter mt-10'>We excel in developing high-quality mobile applications for iOS, Android, and cross-platform environments. Our team of experienced developers leverages the latest technologies and industry best practices to create apps that deliver exceptional performance and user experiences. </p>
                                </div>
                            </div>

                          
                        </div>
                    </div>
                    <div className='pt-lg-5'>
                        <p className='sec2heading text-center mb-0 Seopara1 font-23'> Leveraging the Latest Technologies </p>
                        <p className='sec2heading text-center Seopara1 font-23'> for Your App </p>
                        <div className="container">
                            <div className="row align-items-center justify-content-center">
                            <div className="col-lg-7 textcenter">
                                      <p className='font-23' style={{fontSize:'30px', fontWeight:'500', fontFamily:"Plus Jakarta Sans, sans-serif", }}>AR/VR</p>
                                    <p className='para1 font-18'>We harness the power of Augmented Reality (AR) and Virtual Reality (VR) to create immersive and interactive mobile applications. Our expertise in AR/VR development allows us to deliver innovative solutions that enhance user engagement and provide unique experiences. </p>
                                </div>
                                <div className="col-lg-4">
                                    <img style={{ maxWidth: '100%' }} src={lever} alt="" />
                                </div>
                               
                            </div>

                          
                        </div>
                    </div>

        <section>
            <div className='mt-10 mt-lg-4'>
            <div >
                <Testimonial1/>
              </div>
            </div>
        </section>


{/* <div className='pt-5'>
<Testimonial 
             paragraph="Datametricks has been an exceptional partner in developing our mobile app. Their expertise in AR/VR technology transformed our initial concept into an immersive experience that our users love. The team's professionalism and commitment to quality have exceeded our expectations, and we couldn't be happier with the results." title="Christy Yoland R." subtitle="CEO Founder Loker Indonesia"image={testimonialimage}
             paragraph1="Working with Datametricks Consulting was a seamless experience from start to finish. They developed a cross-platform app that performs flawlessly on both iOS and Android. The team's attention to detail and understanding of our business needs resulted in an app that not only looks great but also delivers outstanding functionality. Highly recommend!"  title1="Christy Yoland R." subtitle1="CEO Founder Loker Indonesia"image1={testimonialimage}
             paragraph2="The team at Datametricks did a fantastic job with our educational app. Their innovative approach to integrating AR features has made learning interactive and fun for our users. We have seen a significant increase in user engagement and positive feedback since the app's launch. Datametricks truly understands how to leverage the latest technologies to create impactful solutions. "title2="Christy Yoland R." subtitle2="CEO Founder Loker Indonesia"image2={testimonialimage}
             paragraph3="Datametricks exceeded our expectations in every way. They delivered a high-quality mobile app that seamlessly integrates with our existing systems. The app has improved our operational efficiency and customer satisfaction. The team's ongoing support and maintenance ensure that our app remains up-to-date and performs optimally. We highly recommend Datametricks for any mobile app development needs. "title3="Christy Yoland R." subtitle3="CEO Founder Loker Indonesia"image3={testimonialimage}
             paragraph4="Our experience with Datametricks has been outstanding. They developed a user-friendly and visually stunning app for our travel business. The team was responsive, knowledgeable, and delivered on time. Their expertise in mobile app development helped us launch a product that stands out in the market. We are grateful for their partnership and look forward to future projects. "title4="Christy Yoland R." subtitle4="CEO Founder Loker Indonesia"image4={testimonialimage}

     />
</div> */}

<section className='mt-lg-4 '>
<div>
      <p className='text-center fw-600 fs-35 plus font-23'>Latest From The <span style={{ color: 'rgba(0, 83, 154, 1)' }}>Blog</span></p>
  </div>
  <Blog1 categoryId={categoryId}/>
</section>


<div className=' mx-lg-5 mt-lg-5 mt-20'>
<Minicontact/>
</div>



<div className='pb-5 mt-5 '>
    <Faq faqData={faqData} />
</div>

                    </div>

                    <section>
  <div>
    <Chatbox/>
  </div>
</section>

          <div>
          <Footer />
          </div>
      
        </div>
    );
}
export default Mobiledev;