import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const Minicontact = ()=>{

const emailiconwebdev = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/Envelope.svg";
const phoneiconwebdev = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/Phone.svg";
const lociconwebdev = "https://d3vqz9qyn2r1xe.cloudfront.net/imges/MapPin.svg";

  // ================ Mailer code start from here =====================
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    phone: "",
  });
  const [responseMessage, setResponseMessage] = useState(""); 
  const [isChecked, setIsChecked] = useState(false); // State for checkbox

  const [loading, setLoading] = useState(false); // State for loader
  const navigate = useNavigate(); // Initialize useNavigate
  const [errors, setErrors] = useState({}); 

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };
  const validateForm = () => {
    let formErrors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^[0-9]{10}$/; // Assumes phone number should be 10 digits

    if (!formData.email || !emailRegex.test(formData.email)) {
      formErrors.email = "Please enter a valid email address.";
    }

    if (!formData.phone || !phoneRegex.test(formData.phone)) {
      formErrors.phone = "Please enter a valid 10-digit phone number.";
    }

    if (!formData.message || formData.message.trim().length < 10) {
        formErrors.message = "Please enter a message with at least 10 characters.";
      }
      if (!isChecked) {
        formErrors.checkbox = "You must agree to the Terms & Conditions.";
      }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }
    setLoading(true); // Show loader

    try {
      const response = await fetch(
        "https://mailer.datametricks.com/send-email.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      const result = await response.json(); // Parse JSON from the response

      setResponseMessage(result.message);

      if (result.status === "success") {
        setTimeout(() => {
          setLoading(false); // Hide loader
          navigate("/Successemailpage"); // Redirect to Thank You page
        }, 1000); // Delay redirect by 2 seconds
      } else {
        setLoading(false); // Hide loader if not successful
      }
    } catch (error) {
      setResponseMessage("Error submitting form.");
      setLoading(false); // Hide loader on error
    }
  };

  // ================ Mailer code end here =====================

    return(
        <div>
          
    
        <div className="row justify-content-center">
            <div className="col-lg-6 pb-3">
                                <h1 className='consultant-heading font-23'>Get a Free <br /> Consultation Today</h1>
                                <p className='cutting-subtitle pt-2 font-18'>"Let's Build Your Dream Website and Grow <br /> Your  Business with us"</p>
                                <div className='pt-4' style={{ borderBottomStyle: 'dashed', width: '70%', color: '#6A6A6A' }}>

                                </div>
                                <p className='reachout pt-4 ms-'>Reach out to us at</p>
                                <div className='d-flex gap-2'>
                                    <div>
                                        <img style={{ width: '30px' }} src={lociconwebdev} alt="" />
                                    </div>
                                    <div>
                                        <p  className=' mb-0'>A40, ITHUM TOWER, </p>
                                        <p className=''>Industrial Area, Sector 62, Noida, Uttar Pradesh 201309 </p>
                                    </div>
                                </div>

                                <div className='d-flex gap-2'>
                                    <div>
                                        <img style={{ width: '30px' }} src={emailiconwebdev} alt="" />
                                    </div>
                                    <div>
                                        <p className='cutting-subtitle mb-0'>contact@datametricks.com </p>

                                    </div>
                                </div>
                                <div className='d-flex gap-2 pt-3'>
                                    <div>
                                        <img style={{ width: '30px' }} src={phoneiconwebdev} alt="" />
                                    </div>
                                    <div>
                                        <p className='cutting-subtitle mb-0'>+91 7303946657  </p>

                                    </div>
                                </div>
                            </div>
            {/* <div className="col-lg 6">
             <div className='contactusform pb-5'>
                                    <div className="ps-5 pe-5 pt-5">
                                        <p className='reachout'>Contact Us</p>
                                        <div className="row">
                                         
                                            <div className="col-lg-6">
                                                <input type="text" placeholder='Your Name' style={{color:'#8C959F'}} />
                                            </div>
                                            <div className="col-lg-6">
                                                <input type="email" placeholder='Your Email'style={{color:'#8C959F'}} />
                                            </div>
                                        </div>
                                        <div className="pt-3">
                                            <input style={{ width: '100%',color:'#8C959F' }} type="phone" id="" placeholder='Mobile No' />
                                        </div>
                                        <div className='pt-3'>
                                            <textarea className='settext2' name="" id="" cols="15" rows="4" placeholder='Your Message' style={{color:'#8C959F'}}></textarea>
                                            <div className='d-flex gap-2 pt-1'>
                                                <input type="checkbox" />
                                                <label style={{ color: '#8C959F' }} htmlFor="vehicle1">I accept the <a href="/Termscondition">Terms & Condition </a></label> <br />
                                            </div>
                                            <div className='pt-3'>
                                                <button className='submit-button text-center'>Submit</button>
                                            </div>
                                        </div>
                                    

                                    </div>

                                </div>
                                </div> */}



<div className="col-lg-5">
    <div className='contactusform pb-5'>
        <div className="ps-5 pe-5 pt-4">
            <p className='reachout'>Contact Us</p>
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-lg-6">
                        <input className='minicontact_name' type="text" id="fname"
                              name="name"
                              value={formData.name}
                              onChange={handleChange}  required placeholder='* Your Name' style={{color:'#8C959F'}} />
                    </div>
                    <div className="col-lg-6">
                        <input className='minicontact_email' type="email" id="email" name="email"   value={formData.email}
                              onChange={handleChange} required placeholder='* Your Email' style={{color:'#8C959F'}} />

                    </div>
                    {errors.email && <p className="error-text" style={{ fontSize: "12px", color: "red", marginTop: "5px" }}>{errors.email}</p>}

                </div>
                <div className="pt-3">
                    <input style={{ width: '100%',color:'#8C959F' }} type="tel"  name="phone"
                            value={formData.phone}
                            onChange={handleChange} required placeholder='* Mobile No' />
                         {errors.phone && <p className="error-text" style={{ fontSize: "12px", color: "red", marginTop: "10px" }}>{errors.phone}</p>}

                </div>
                <div className='pt-3'>
                    <textarea className='settext2' name="message"  value={formData.message}
                          onChange={handleChange}
                          form="usrform" required cols="15" rows="4" placeholder='Your Message' style={{color:'#8C959F'}}></textarea>
                            {errors.message && (
              <p
                className="error-text"
                style={{ fontSize: "12px", color: "red", marginTop: "5px" }}
              >
                {errors.message}
              </p>
            )}
                    <div className='d-flex gap-2 pt-2'>
                        <div>
                        <input type="checkbox" value=""
                            id="flexCheckDefault"
                            onChange={handleCheckboxChange}/>
                        </div>
                    
                    <div>
                        <label className="font-15" style={{ color: '#8C959F'}} htmlFor="terms">I accept the <a href="/Termscondition">Terms & Condition </a></label> <br />
                    </div>
                    </div>
                    {errors.checkbox && <p className="error-text mb-0" style={{ fontSize: "12px", color: "red", }}>{errors.checkbox}</p>}

                    <div className='pt-3'>
                        <button type="submit" className='submit-button text-center' disabled={loading}>
                        {loading ? (
                            <>
                        
                              <div className="spinner"></div> {/* Spinner */}
                              {/* <span className="">Sending...</span> */}
                            
                            </>
                          ) : (
                            "Submit"
                          )}
                            
                            </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

            </div>
        
          
           
           
             
        </div>
    )
}
export default Minicontact