import React, { useState, useEffect } from 'react';
import Header from "../Components/Header";
import Footer from "../Components/footer";
import Bigcontact from '../Components/Bigcontact';
import Modal from 'react-bootstrap/Modal';
import Chatbox from "../Components/Chatbox";
import SEOPage from "../Components/SEOPage";
import Blog1 from "../Components/Blog1";
import { Helmet } from "react-helmet";


const Performancemarketing = () => {

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    fetch('https://blog.datametricks.com/wp-json/wp/v2/categories')
      .then((response) => response.json())
      .then((data) => setCategories(data))
      .catch((error) => console.error('Error fetching categories:', error));
  }, []);

  const categoryId=14;

  const serviceSchemaData5 = 
  [
  {
    "@context": "https://schema.org",
    "@type": "Service",
    "serviceType": "Performance Marketing",
    "provider": {
      "@type": "Organization",
      "name": "Datametricks Consulting Pvt Ltd",
      "url": "https://www.datametricks.com/",
      "logo": "https://d3vqz9qyn2r1xe.cloudfront.net/imges/comlogo.svg",
      "contactPoint": {
        "@type": "ContactPoint",
        "telephone": "+91 7303946657",
        "contactType": "Customer Service",
        "email": "contact@datametricks.com"
      }
    },
    "description": "Datametricks offers performance marketing services focused on delivering measurable results through data-driven campaigns, including PPC, social media ads, and more.",
    "areaServed": {
      "@type": "Place",
      "name": "India"
    }
  }
];

const perimg1 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/per-img1.svg';
const perimg2 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/per-img2.svg';
const perimg3 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/per-img3.svg';
const perimg4 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/per-img4.svg';
const perimg5 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/per-img5.svg';
const perimg6 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/per-img6.svg';
const perimg7 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/per-img7.svg';
const perimg8 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/per-img8.svg';
const perimg9 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/per-img9.svg';
const perimg10 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/per-img10.svg';
const perimg11 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/per-img11.svg';
const perimg12 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/per-img12.svg';
const pericon1 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/pericon-1.svg';
const pericon2 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/pericon-2.svg';
const pericon3 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/pericon-3.svg';
const pericon4 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/pericon-4.svg';
const pericon5 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/pericon-5.svg';
const per_img_1 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/per-img-1.svg';
const per_img_2 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/per-img-2.svg';
const per_img_3 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/per-img-3.svg';
const per_img_4 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/per-img-4.svg';
const per_img_5 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/per-img-5.svg';
const per_img_6 = 'https://d3vqz9qyn2r1xe.cloudfront.net/imges/per-img-6.svg';

const [show, setShow] = useState(false);
const handleClose = () => setShow(false);
const handleShow = () => setShow(true);
 
  return (
    <div>
    <SEOPage 
                title="Performance Marketing Services | Drive Results with Datametricks"
                description="Datametricks delivers performance marketing services designed to maximize your ad
spend ROI through targeted campaigns, PPC, and data-driven strategies that lead to
measurable results."
                canonicalUrl="https://www.datametricks.com/services/performance-marketing"/>
      <Helmet>
             <script type="application/ld+json">
                  {JSON.stringify(serviceSchemaData5)}
             </script>
      </Helmet>
      
      <section>
        <Header />
      </section>

    <section  style={{marginTop:'7%'}}>

    <section>
  <div className="container persec-1 mt-5 ">
    <p className="fw-800 plus fs-40 responsive-text font-23">
      <span>Stop </span>
      <span style={{color:'#E15E00'}}>Wasting Ad Spend</span>
      <span>. Achieve Measurable </span>
      <span style={{color:'#009F49'}}>Growth </span>
      <span>with</span>
      <span style={{color:'#00539A'}}> Performance Marketing</span>
    </p>
    <p className="fw-500 fs-18 plus font-18" style={{color:'rgba(34, 34, 34, 0.6)'}}>
      Drive More Sales, Leads, and ROI with Targeted Online Advertising Strategies
    </p>
    <div>
    <button type="button" className="btn btn-sm py-2 px-4 mt-lg-4 mt-05 " onClick={handleShow} style={{fontSize:'18px',fontWeight:'500',backgroundColor:'rgba(227, 139, 35, 1)',color:'white',borderRadius:'6px',border:'none',outline:'none'}}>
      Get a Free Consultation Today
    </button>

    <Modal show={show} onHide={handleClose} className='modal1'   >
      <Modal.Header closeButton className='modelheader1' ></Modal.Header>
        <Modal.Body className='modelbody1'>  
           <Bigcontact/>
        </Modal.Body>
       
      </Modal>
    </div>
  </div>
</section>

  
<section>
  <div className="container mt-lg-5">
    <div className="row gap-4 mt-30">
      <div className="col-lg-5 col-md-6 col-sm-12">
        <img src={perimg1} alt="" className="img-fluid" />
      </div>
      <div className="col-lg-3 col-md-6 col-sm-6 textalign-cen d-none d-sm-none d-lg-block d-md-block">
        <img src={perimg2} alt="" className="img-fluid" />
      </div>
      <div className="col-lg-3 col-md-6 col-sm-6 textalign-cen d-none d-sm-none d-lg-block d-md-block">
        <img src={perimg3} alt="" className="img-fluid " />
      </div>
    </div>
  </div>
</section>

<section className="mt-5 d-none d-sm-none d-lg-block d-md-block">
  <div className="Per-bgimg1 " style={{ position: 'relative' }}>
    <div className="content-container">
      <p className="fs-18 plus d-block d-lg-block d-sm-none d-md-none ">Performance marketing offers a data-driven alternative, allowing you to focus your advertising budget on what truly matters: achieving your business goals. Whether you're looking to increase sales, generate qualified leads, or boost website traffic, our performance marketing services can help you reach the right audience at the right time. We track and analyze every click and conversion, ensuring your advertising budget delivers measurable results and a maximized return on investment (ROI).</p>
    </div>
  </div>
</section>

<section>
  <div className="container percon-3 text-center mt-lg-4 ">
    <p className="plus fs-35 fw-700 mb-0 perheading ">Maximize Your Conversions with Our Extensive</p>
    <p className="plus fs-35 fw-700 perheading">Performance Marketing Solutions</p>
    <p className="plus fs-18 mb-0 persubheading" style={{color:'rgba(121, 134, 159, 1)'}}>We offer a full spectrum of performance marketing services designed to drive targeted traffic,</p>
    <p className="plus fs-18 persubheading" style={{color:'rgba(121, 134, 159, 1)'}}>generate leads, and maximize your return on investment (ROI).</p>
  </div>
</section>

      <section>
        <div className="container  mt-lg-5 mt-30" >
    <div className="row">
        <div className="col-lg-12">
            <div className="d-flex flex-wrap card-container ">
                <div  className="">
                    <img src={perimg4}  className="img-fluid " style={{minWidth:'100%'}}  alt="" />
                </div>
                <div className="text-container pt-lg-5 textcenter " style={{width:'70%'}}>
                        <h5 className="fw-600 fs-18  plus "> Search Engine Marketing (SEM) & Pay-Per-Click (PPC) Advertising</h5>
                        <p className="fw-400 fs-16 plus pt-2 " style={{color:'rgba(121, 134, 159, 1)'}}>Dominate search engine results pages (SERPs) and reach potential customers actively searching for your products or services. We'll craft compelling ad copy, optimize campaigns for maximum visibility, and meticulously track conversions to ensure you get the most out of your PPC budget.  </p>
                    </div>
            </div>
        </div>
    </div>
  
   
    <div className="container mt-4">
        <div className="row justify-content-end">
        <div className="col-lg-12">
            <div className="d-flex flex-wrap flex-column-reverse flex-sm-row  card-container-right">
                <div className="text-container pt-lg-5 textcenter" style={{width:'75%'}}>
                <h5 className="fw-600 fs-18 plus "> Social Media Advertising</h5>
                <p className="fw-400 fs-16 plus pt-2 " style={{color:'rgba(121, 134, 159, 1)'}}>Tap into the massive potential of social media platforms like Facebook, Instagram, and LinkedIn. Utilize targeted advertising to connect with your ideal audience, build brand awareness, and drive qualified leads and sales.   </p>

                    </div>
                    <div  className="">
                    <img src={perimg5} className="img-fluid" style={{minWidth:'100%'}}  alt="" />
                </div>
            </div>
        </div>
        </div>
    </div>
        </div>

    <div className="container  mt-4" >
    <div className="row">
        <div className="col-lg-12">
            <div className="d-flex flex-wrap card-container ">
                <div  className="">
                    <img src={perimg6}  className="img-fluid" style={{minWidth:'100%'}}  alt="" />
                </div>
                <div className="text-container pt-lg-5 textcenter " style={{width:'70%'}}>
                        <h5 className="fw-600 fs-18 plus">Display Advertising</h5>
                        <p className="fw-400 fs-16 plus pt-2" style={{color:'rgba(121, 134, 159, 1)'}}>Reach your target audience across a vast network of websites with visually engaging display ads. We'll develop strategic placements and leverage retargeting strategies to keep your brand at the forefront of their minds.   </p>
                    </div>
            </div>
        </div>
    </div>
  
   
    <div className="container mt-4">
        <div className="row justify-content-end">
        <div className="col-lg-12 ">
            <div className="d-flex flex-wrap flex-column-reverse flex-sm-row  card-container-right ">
                <div className="text-container pt-lg-5 textcenter " style={{width:'75%'}}>
                <h5 className="fw-600 fs-18 plus ">Video Advertising</h5>
                <p className="fw-400 fs-16 plus pt-2 " style={{color:'rgba(121, 134, 159, 1)'}}>Harness the power of storytelling with captivating video ads. We'll create high-quality video content that resonates with your audience and drives engagement and conversions.    </p>

                    </div>
                    <div  className="">
                    <img src={perimg7} className="img-fluid" style={{minWidth:'100%'}}  alt="" />
                </div>
            </div>
        </div>
        </div>
    </div>
        </div>

        <div className="container  mt-4" >
    <div className="row">
        <div className="col-lg-12">
            <div className="d-flex flex-wrap card-container ">
                <div  className="">
                    <img src={perimg12}  className="img-fluid" style={{minWidth:'100%'}}  alt="" />
                </div>
                <div className="text-container pt-lg-5 textcenter " style={{width:'70%'}}>
                        <h5 className="fw-600 fs-18 plus">Remarketing & Retargeting</h5>
                        <p className="fw-400 fs-16 plus pt-2" style={{color:'rgba(121, 134, 159, 1)'}}>Reconnect with website visitors who have already shown interest in your brand. Our retargeting campaigns will bring them back to your site, reminding them of your offerings and enticing them to convert.   </p>
                    </div>
            </div>
        </div>
    </div>
  
   
    <div className="container mt-4">
        <div className="row justify-content-end">
        <div className="col-lg-12">
            <div className="d-flex flex-wrap flex-column-reverse flex-sm-row  card-container-right">
                <div className="text-container pt-lg-5 textcenter" style={{width:'75%'}}>
                <h5 className="fw-600 fs-18 plus ">Affiliate Marketing</h5>
                <p className="fw-400 fs-16 plus pt-2 " style={{color:'rgba(121, 134, 159, 1)'}}>Partner with a network of established influencers and publishers to expand your reach and drive targeted traffic. We'll manage your affiliate program, track performance, and ensure it delivers a strong return.     </p>

                    </div>
                    <div  className="">
                    <img src={perimg8} className="img-fluid" style={{minWidth:'100%'}}  alt="" />
                </div>
            </div>
        </div>
        </div>
    </div>
        </div>

        <div className="container  mt-4" >
    <div className="row">
        <div className="col-lg-12">
            <div className="d-flex flex-wrap card-container ">
                <div  className="">
                    <img src={perimg9}  className="img-fluid" style={{minWidth:'100%'}}  alt="" />
                </div>
                <div className="text-container pt-lg-5 textcenter " style={{width:'70%'}}>
                        <h5 className="fw-600 fs-18 plus">Conversion Rate Optimization (CRO)</h5>
                        <p className="fw-400 fs-16 plus pt-2" style={{color:'rgba(121, 134, 159, 1)'}}> Maximize the effectiveness of your website by optimizing it for conversions. We'll analyze user behavior, identify conversion roadblocks, and implement A/B testing strategies to optimize your landing pages and checkout process, leading to more sales and leads.    </p>
                    </div>
            </div>
        </div>
    </div>
  
   
    <div className="container mt-4">
        <div className="row justify-content-end">
        <div className="col-lg-12">
            <div className="d-flex flex-wrap flex-column-reverse flex-sm-row  card-container-right">
                <div className="text-container pt-lg-5 textcenter" style={{width:'75%'}}>
                <h5 className="fw-600 fs-18 plus "> Content Marketing (Performance-Driven)</h5>
                <p className="fw-400 fs-16 plus pt-2 " style={{color:'rgba(121, 134, 159, 1)'}}>Craft high-quality content that educates, engages, and ultimately converts your audience. We'll focus on creating targeted content that drives leads and sales, aligning with your overall performance marketing strategy.      </p>

                    </div>
                    <div  className="">
                    <img src={perimg10} className="img-fluid" style={{minWidth:'100%'}}  alt="" />
                </div>
            </div>
        </div>
        </div>
    </div>
        </div>
        
        <div className="container  mt-4" >
    <div className="row">
        <div className="col-lg-12">
            <div className="d-flex flex-wrap card-container ">
                <div  className="">
                    <img src={perimg11}  className="img-fluid" style={{minWidth:'100%'}}  alt="" />
                </div>
                <div className="text-container pt-lg-5 textcenter " style={{width:'70%'}}>
                        <h5 className="fw-600 fs-18 plus">Influencer Marketing (Performance-Driven)</h5>
                        <p className="fw-400 fs-16 plus pt-2" style={{color:'rgba(121, 134, 159, 1)'}}>Partner with relevant influencers to amplify your brand message and reach a wider audience. We'll identify the right influencers for your target market and develop performance-driven campaigns that deliver measurable results.    </p>
                    </div>
            </div>
        </div>
    </div>
    </div>
      </section>


    </section>
 
<section>

<section>
  <div className="container text-center mt-lg-5 mt-10">
    <p className="plus fs-35  fw-700 mb-0 font-23">Our Services Can Deliver the Benefits that Matters Most</p>
    <p className="plus fs-18  mb-0 font-18 pt-2" style={{ color: 'rgba(121, 134, 159, 1)' }}>Stop throwing money at generic advertising campaigns. Our performance marketing services</p>
    <p className="plus fs-18 font-18 " style={{ color: 'rgba(121, 134, 159, 1)' }}>are designed to deliver tangible results that fuel your business growth.</p>
  </div>
</section>

</section>

 <section className="mt-lg-5 mt-30 container ">
  <div className="row ">
    <div className="col-lg-6 col-md-6 col-sm-12 ">
          <div style={{backgroundColor:'rgba(239, 90, 111, 1)',borderRadius:'10px',height:'220px'}}>
              <div className="d-flex p-lg-3 pa-5 gap-lg-4 gap0 ">
                <div >
                  < img src={pericon1} alt=""  />
                </div>
                <div  className="pt-3">
                 <p className="vl "></p>
                </div>
                <div className="pt-3">
                    <h2 className="fs-20 font-16 fw-500 plus text-white" style={{color:'rgba(255, 255, 255, 1)'}}>Increased ROI</h2>
                    <p className="fs-16 font-14 fw-400 plus text-white" style={{color:'rgba(255, 255, 255, 0.8)' }}>We go beyond vanity metrics. Our data-driven approach ensures every marketing dollar is spent strategically. You'll see a clear return on investment through increased sales, leads, or website traffic, depending on your specific goals. </p>
                </div>
              </div>
          </div>
    </div>
    <div className="col-lg-6 col-md-6 col-sm-12">
    <div className="percardsec-1"  style={{backgroundColor:'rgba(83, 100, 147, 1)',borderRadius:'10px',height:'220px'}}>
              <div className="d-flex p-lg-3 pa-5 gap-lg-4 gap0">
                <div >
                  < img src={pericon2} alt=""  />
                </div>
                <div  className="pt-3">
                 <p className="vl "></p>
                </div>
                <div className="pt-3">
                    <h2 className="fs-20 font-16 fw-500 plus text-white" style={{color:'rgba(255, 255, 255, 1)'}}> Improved Conversion Rates</h2>
                    <p className="fs-16 font-14 fw-400 plus text-white" style={{color:'rgba(255, 255, 255, 0.8)' }}>We don't just drive traffic; we convert visitors into customers. Through strategic campaign optimization and landing page improvements, we'll turn website visits into leads and sales, maximizing the effectiveness of your online presence. </p>
                </div>
              </div>
          </div>
</div>
  </div>

  <div className="row ">
    <div className="col-lg-6 col-md-6 col-sm-12 ">
          <div className="mt-4" style={{backgroundColor:'rgba(26, 77, 46, 1)',borderRadius:'10px',height:'220px'}}>
              <div className="d-flex p-lg-3 pa-5 gap-lg-4 gap0">
                <div >
                  < img src={pericon3} alt=""  />
                </div>
                <div  className="pt-3">
                 <p className="vl "></p>
                </div>
                <div className="pt-3">
                    <h2 className="fs-20 font-16 fw-500 plus text-white" style={{color:'rgba(255, 255, 255, 1)'}}>More Qualified Leads and Sales</h2>
                    <p className="fs-16 font-14 fw-400 plus text-white" style={{color:'rgba(255, 255, 255, 0.8)' }}>Forget unqualified leads. Our targeted campaigns reach the right audience at the right time. You'll generate a steady stream of high-quality leads more likely to convert into paying customers.  </p>
                </div>
              </div>
          </div>
    </div>
    <div className="col-lg-6 col-md-6 col-sm-12">
    <div className="mt-4"  style={{backgroundColor:'rgba(221, 87, 70, 1)',borderRadius:'10px',height:'220px'}}>
              <div className="d-flex p-lg-3 pa-5 gap-lg-4 gap0">
                <div >
                  < img src={pericon4} alt=""  />
                </div>
                <div  className="pt-3">
                 <p className="vl "></p>
                </div>
                <div className="pt-3">
                    <h2 className="fs-20 font-16 fw-500 plus text-white" style={{color:'rgba(255, 255, 255, 1)'}}> Enhanced Brand Awareness</h2>
                    <p className="fs-16 font-14 fw-400 plus text-white" style={{color:'rgba(255, 255, 255, 0.8)' }}>Increase brand visibility and recognition across various online channels. Consistent, targeted marketing builds brand awareness and positions you as a leader in your industry. </p>
                </div>
              </div>
          </div>
</div>
  </div>

  <div className="row justify-content-center">
    <div className="col-lg-6 col-md-6 col-sm-12">
    <div className="mt-4" style={{backgroundColor:'rgba(79, 74, 69, 1)',borderRadius:'10px',height:'220px'}}>
              <div className="d-flex p-lg-3 pa-5 gap-lg-4 gap0">
                <div >
                  < img src={pericon5} alt=""  />
                </div>
                <div  className="pt-3">
                 <p className="vl "></p>
                </div>
                <div className="pt-3">
                    <h2 className="fs-20 font-16 fw-500 plus text-white" style={{color:'rgba(255, 255, 255, 1)'}}>Targeted Audience Reach</h2>
                    <p className="fs-16 font-14 fw-400 plus text-white" style={{color:'rgba(255, 255, 255, 0.8)' }}> Gone are the days of scattershot marketing. We leverage data and analytics to identify your ideal customer profile. Our targeted campaigns ensure your message reaches the exact audience most likely to convert. </p>
                </div>
              </div>
          </div>
    </div>
  </div>
  
</section> 


<section>
  <div className="container text-center mt-lg-5 mt-10">
    <p className="plus fs-35 font35 fw-700 mb-0 font-23">Our Data-Driven Performance  </p>
    <p className="plus fs-35 font35 fw-700 font-23">Marketing Process </p>
    <p className="plus fs-18 font18 mb-0 font-18" style={{ color: 'rgba(121, 134, 159, 1)' }}>We believe in a transparent and collaborative approach to performance marketing. Here's how </p>
    <p className="plus fs-18 font18 font-18" style={{ color: 'rgba(121, 134, 159, 1)' }}>we work with you to achieve your business objectives</p>
  </div>
</section>

<section className="mt-lg-4 pb-lg-5 p-lg-5   paddingstyle" style={{backgroundColor:'rgba(250, 250, 250, 1)'}}>
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-lg-5">
          <img src={per_img_1} alt="" className="img-fluid" />
      </div>
      <div className="col-lg-6 textcenter">
          <p className="mt-lg-5 mt-10 fw-600 fs-21 plu font-23">Initial Consultation</p>
          <p className="fs-17 fw-400 plus font-18" style={{color:'rgba(121, 134, 159, 0.8)'}}>We begin with a comprehensive consultation to understand your unique business goals, target audience, and budget. We'll discuss your current marketing efforts and identify areas for improvement. </p>
    </div>
    </div>

    <div className="row mt-lg-5 textcenter justify-content-center ">
    <div className="col-lg-6">
          <p className="mt-lg-5 fw-600 fs-21 plus font-23">Competitive Analysis</p>
          <p className="fs-17 fw-400 plus font-18" style={{color:'rgba(121, 134, 159, 0.8)'}}>We conduct a thorough analysis of your competitive landscape. This helps us understand your industry trends, identify potential opportunities, and develop targeted strategies to differentiate your brand.  </p>
    </div>
      <div className="col-lg-5 mt-10 justify-content-center">
          <img src={per_img_2} alt="" className="img-fluid" />
      </div>
     
    </div>

    <div className="row mt-lg-5 mt-10 justify-content-center">
      <div className="col-lg-5">
          <img src={per_img_3} alt="" className="img-fluid" />
      </div>
      <div className="col-lg-6 textcenter">
          <p className="mt-lg-5 mt-30 fw-600 fs-21 plus font-23">Campaign Strategy Development</p>
          <p className="fs-17 fw-400 plus font-18" style={{color:'rgba(121, 134, 159, 0.8)'}}>Based on our findings, we'll work collaboratively with you to develop a customized performance marketing strategy. This strategy will specify the channels we'll utilize, budget allocation, and key performance indicators (KPIs) to measure success.  </p>
    </div>
    </div>

    <div className="row mt-lg-5 mt-35 justify-content-center">
    <div className="col-lg-6 textcenter">
          <p className="mt-lg-5 fw-600 fs-21 font-23 plus margintopstyle">Creative Development</p>
          <p className="fs-17 fw-400 plus font-18" style={{color:'rgba(121, 134, 159, 0.8)'}}>Our creative team will craft compelling ad copy, design high-performing landing pages, and develop engaging video content (if applicable) that resonates with your target audience and drives conversions.   </p>
    </div>
      <div className="col-lg-5 d-none d-sm-none d-md-block d-lg-block">
          <img src={per_img_4} alt="" className="img-fluid" />
      </div>
     
    </div>

    <div className="row mt-lg-5 justify-content-center">
      <div className="col-lg-5">
          <img src={per_img_5} alt="" className="img-fluid" />
      </div>
      <div className="col-lg-6 textcenter">
          <p className="mt-lg-5 mt-10 fw-600 fs-21 plus font-23">Campaign Implementation and Management</p>
          <p className="fs-17 fw-400 plus font-18" style={{color:'rgba(121, 134, 159, 0.8)'}}>We'll meticulously launch your campaigns across the chosen channels, ensuring optimal targeting and budget allocation. Our team will actively manage your campaigns, making adjustments in real-time to maximize performance.   </p>
    </div>
    </div>

    <div className="row mt-lg-5 justify-content-center">
    <div className="col-lg-6 textcenter">
          <p className="mt-lg-5 fw-600 fs-21 plus font-23">Ongoing Monitoring, Optimization, and Reporting</p>
          <p className="fs-17 fw-400 plus font-18" style={{color:'rgba(121, 134, 159, 0.8)'}}>We believe in continuous improvement. We'll closely monitor campaign performance, analyze data, and conduct A/B testing to identify optimization opportunities. You'll receive regular reports with actionable insights, keeping you informed of progress and campaign effectiveness.    </p>
    </div>
      <div className="col-lg-5 mb-10">
          <img src={per_img_6} alt="" className="img-fluid" />
      </div>
     
    </div>
  </div>
</section>
     
<section>
  <div>
    <Chatbox/>
  </div>
</section>

<section className='mt-lg-4 '>
<div>
      <p className='text-center fw-600 fs-35 plus font-23'>Latest From The <span style={{ color: 'rgba(0, 83, 154, 1)' }}>Blog</span></p>
  </div>
  <div className='mt-4'>
  <Blog1 categoryId={categoryId}/>
  </div>
</section>

      <section className="mt-lg-5">
        <Footer />
      </section>
    </div>
  );
};
export default Performancemarketing;
